body.rtl {
  direction: rtl !important;
  text-align: right;

  .swiper {
    direction: ltr;
  }

  .topbar {
    direction: rtl !important;
  }

  .form-wrapper {
    input,
    textarea {
      direction: rtl !important;
    }
  }

  .contact-signle {
    .icon {
      margin-right: 0;
      margin-left: 15px;
    }
  }

  .counter-single {
    .counter-icon {
      margin-left: 15px;
      margin-right: 0;
    }
  }

  .breadcrumb-item + .breadcrumb-item::before {
    float: right;
    padding-left: 0.5rem;
  }

  .sidebar-widget-title {
    span {
      &::before {
        right: auto;
        left: -10px;
      }
      &::after {
        right: auto;
        left: -20px;
      }
    }
  }

  .testimonial-single {
    .quote-icon {
      right: auto;
      left: 20px;
    }
  }

  .hero-style-three {
    .home3-banner {
      right: auto;
      left: 110px;
    }
  }

  .describe-content {
    .describe-list {
      li {
        padding-left: 0;
        padding-right: 30px;

        &::before {
          left: auto;
          right: 0;
        }
      }
    }
  }
}
