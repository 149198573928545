$primary-color1: #291fbc;
$primary-color2: #291fbc;
$primary-color3: #291fbc;
$text-color: #1f2230;
$text-color2: #696969;
$countdown-color: #444444;
$text-color-light: #d2d2d2;
$border-color: #eeeeee;
$white: #fff;
$bg-light: #f5f5f5;
$dark-color: #0a0a0f;
$dark-color2: #0c0321;

$main-font: 'Cairo', sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $main-font;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: $main-font;
}

a {
  color: unset;
  text-decoration: none;
  &:focus {
    outline: 0 solid;
  }
  &:hover {
    text-decoration: none;
    color: unset;
  }
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
}

ul {
  list-style: none;
}

i.bx {
  vertical-align: middle;
}

/*================================================
2. Mixins Css
=================================================*/

@mixin flex($position) {
  display: flex;
  justify-content: $position;
  flex-wrap: wrap;
  align-items: center;
}

@mixin hover-style {
  position: relative;
  transition: 0.4s ease;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1px;
    background: rgba($primary-color1, 0.5);
    height: 0px;
    transition: 0.5s ease-in-out;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
    height: 0px;
    background: rgba($primary-color1, 0.5);
    transition: 0.5s ease-in-out;
    z-index: 1;
  }
  &:hover {
    border-top: 1px solid rgba($primary-color1, 0.5);
    border-bottom: 1px solid rgba($primary-color1, 0.5);

    &::before {
      height: 100%;
    }

    &::after {
      height: 100%;
    }
  }
}

// xl-device=====
@mixin fifteen-down-device {
  @media (max-width: 1500px) {
    @content;
  }
}
@mixin fourteen-to-fifteen-device {
  @media (min-width: 1400px) and (max-width: 1499px) {
    @content;
  }
}
@mixin xxl-device {
  @media (min-width: 1400px) and (max-width: 1599px) {
    @content;
  }
}
@mixin xxl-down-device {
  @media (max-width: 1399px) {
    @content;
  }
}
@mixin xl-down-device {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin xl-device {
  @media (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}
@mixin lg-device {
  @media (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}
@mixin lg-up-device {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin lg-down-device {
  @media (max-width: 991px) {
    @content;
  }
}
// md-device============
@mixin md-device {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}
@mixin md-up-device {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin md-down-device {
  @media (max-width: 767px) {
    @content;
  }
}
// sm-device
@mixin sm-device {
  @media (min-width: 576px) and (max-width: 768px) {
    @content;
  }
}
@mixin sm-down-device {
  @media (max-width: 576px) {
    @content;
  }
}
@mixin sm-down-device-lower {
  @media (max-width: 350px) {
    @content;
  }
}
//   padding
.pt-120 {
  padding-top: 120px;

  @include lg-device() {
    padding-top: 90px;
  }

  @include lg-down-device() {
    padding-top: 80px;
  }
}

.pb-120 {
  padding-bottom: 120px;

  @include lg-device() {
    padding-bottom: 90px;
  }

  @include lg-down-device() {
    padding-bottom: 80px;
  }
  @include md-down-device() {
    padding-bottom: 60px;
  }
}

.pt-80 {
  padding-top: 80px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-60 {
  padding-bottom: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-30 {
  margin-bottom: 30px;
}

.swiper-fix {
  margin: -12px;
  padding: 12px;
}

.para {
  font-size: 16px;
  font-weight: 400;
  color: $text-color2;
  font-family: $main-font;
  line-height: 28px;
}

.eg-card {
  border-radius: 5px;
  border: 1px solid $border-color;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.eg-btn {
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  transition: all 0.4s ease;
  text-transform: capitalize;
  font-family: $main-font;
}

.btn--primary {
  background: $text-color;
  color: $white;
  position: relative;
  z-index: 1;
  white-space: nowrap;

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $primary-color1;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 5px;
  }
  &::after {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $primary-color1;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 5px;
  }
  &:hover {
    color: $white;

    &::before {
      transform: rotateX(90deg);
    }
    &::after {
      transform: rotateY(90deg);
    }
  }
}
.btn--primary2 {
  background: $text-color;
  color: $white;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  border-radius: 100px;

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $primary-color2;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 100px;
  }
  &::after {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $primary-color2;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 100px;
  }
  &:hover {
    color: $white;

    &::before {
      transform: rotateX(90deg);
    }
    &::after {
      transform: rotateY(90deg);
    }
  }
}
.btn--primary3 {
  background: $text-color;
  color: $white;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  border-radius: 100px;

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $primary-color3;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 100px;
  }
  &::after {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $primary-color3;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 100px;
  }
  &:hover {
    color: $white;

    &::before {
      transform: rotateX(90deg);
    }
    &::after {
      transform: rotateY(90deg);
    }
  }
}
.btn--primary3-outline {
  border: 1px solid $primary-color3;
  color: $primary-color3;
  white-space: nowrap;
  transition: all 0.52s ease;
  &:hover {
    background: $primary-color3;
    color: $white;
  }
}

.btn--lg {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 17px 32px;

  @include md-down-device() {
    padding: 12px 27px;
  }
}
.btn--md {
  padding: 14px 45px;
  font-size: 16px;
  font-weight: 700;
  @include md-down-device() {
    padding: 10px 40px;
  }
}
.btn--sm {
  font-size: 16px;
  font-weight: 600;
  padding: 5px 12px;
}

.header-btn {
  background-color: $primary-color1;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  font-family: $main-font;
  padding: 9px 20px;
}

.action-btn {
  min-width: 26px;
  height: 26px;
  line-height: 0px;
  border: none;
  outline: none;

  &.red {
    background: $primary-color2;
  }
  &.green {
    background: $primary-color1;
  }
}

.section-title1 {
  text-align: center;
  margin-bottom: 60px;
  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: $text-color;
    margin-top: -5px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    font-family: $main-font;
    color: $text-color2;
  }
}
.section-title2 {
  text-align: center;
  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: $text-color;
    margin-top: -5px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    font-family: $main-font;
    color: $text-color2;
  }
}

.fixed-icons {
  position: fixed;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  z-index: 3;
  bottom: 30px;

  a {
    background: $primary-color1;
    border: 1px solid #fff;
    height: 50px;
    width: 50px;
    display: inline-block;
    border-radius: 50%;
    font-size: 1.5rem;
    text-align: center;
    line-height: 50px;
    color: #fff;
    transition: all 0.4s ease-in;

    &.whatsapp {
      background: #25d366;
    }
  }
}

.section-title4 {
  text-align: center;
  margin-bottom: 60px;
  h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: $text-color;
    margin-top: -5px;
    position: relative;
    display: inline-block;
    @include md-down-device() {
      font-size: 2.1rem;
    }
    &::before {
      content: url('../../../public/images/icons/sec-arro1.svg');
      position: absolute;
      top: -5px;
      left: -55px;
      @include md-down-device() {
        left: -45px;
      }
    }
    &::after {
      content: url('../../../public/images/icons/sec-arro2.svg');
      position: absolute;
      top: -5px;
      right: -55px;
      @include md-down-device() {
        right: -45px;
      }
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    font-family: $main-font;
    color: $text-color2;
  }
}

.hover-style {
  background: $white;
  position: relative;
  z-index: 1;

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $primary-color1;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 5px;
    opacity: 0;
  }
  &::after {
    width: 100%;
    height: 100%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    background: $primary-color1;
    transition: all 0.52s;
    z-index: -1;
    border-radius: 5px;
    opacity: 0;
  }
  &:hover {
    &::before {
      transform: rotateX(90deg);
      opacity: 0.2;
    }
    &::after {
      transform: rotateY(90deg);
      opacity: 0.2;
    }
  }
}
.hover-border1 {
  background: $white;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0%;
    right: 0%;
    background: $primary-color3;
    transition: all 0.52s;
    z-index: -1;
  }
  &:hover {
    &::before {
      width: 100%;
    }
  }
}
.hover-border2 {
  background: $white;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0%;
    right: 0%;
    background: $primary-color2;
    transition: all 0.52s;
    z-index: -1;
  }
  &:hover {
    &::before {
      width: 100%;
    }
  }
}
.hover-border3 {
  background: $white;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    width: 0%;
    height: 2px;
    position: absolute;
    bottom: 0%;
    right: 0%;
    background: $primary-color3;
    transition: all 0.52s;
    z-index: -1;
  }
  &:hover {
    &::before {
      width: 100%;
    }
  }
}

/*=======================================
 3. preloader start
=======================================*/

.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: $primary-color1;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  @-webkit-keyframes loader {
    0%,
    10%,
    100% {
      width: 80px;
      height: 80px;
    }
    65% {
      width: 150px;
      height: 150px;
    }
  }
  @keyframes loader {
    0%,
    10%,
    100% {
      width: 80px;
      height: 80px;
    }
    65% {
      width: 150px;
      height: 150px;
    }
  }
  @-webkit-keyframes loaderBlock {
    0%,
    30% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    55% {
      background-color: #fff;
    }
    100% {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  @keyframes loaderBlock {
    0%,
    30% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    55% {
      background-color: $text-color;
    }
    100% {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  @-webkit-keyframes loaderBlockInverse {
    0%,
    20% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    55% {
      background-color: #fff;
    }
    100% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  @keyframes loaderBlockInverse {
    0%,
    20% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    55% {
      background-color: $text-color;
    }
    100% {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  &.style-2 {
    background: $primary-color2;
  }
  &.style-3 {
    background: $primary-color3;
  }
}
.preloader .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  -webkit-animation: loader 1.2s infinite ease-in-out;
  animation: loader 1.2s infinite ease-in-out;
}
.preloader .loader span {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  -webkit-animation: loaderBlock 1.2s infinite ease-in-out both;
  animation: loaderBlock 1.2s infinite ease-in-out both;
}
.preloader .loader span:nth-child(1) {
  top: 0;
  left: 0;
}
.preloader .loader span:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation: loaderBlockInverse 1.2s infinite ease-in-out both;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}
.preloader .loader span:nth-child(3) {
  bottom: 0;
  left: 0;
  -webkit-animation: loaderBlockInverse 1.2s infinite ease-in-out both;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}
.preloader .loader span:nth-child(4) {
  bottom: 0;
  right: 0;
}

/*=======================================
  3. search area start
=======================================*/

.mobile-search {
  background: rgba(#000, 0.85);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  position: fixed;
  cursor: pointer;
  transform: scale(0);
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.65s ease;
  padding: 35px 100px;

  @include md-down-device() {
    padding: 20px 20px;
  }

  label {
    color: #fff;
    margin-bottom: 20px;
    font-family: $main-font;
  }

  &.slide {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }

  input {
    border: none;
    border-radius: unset;
    border-bottom: 1px solid $border-color;
    padding-left: 0px;
    padding-bottom: 5px;
    width: 100%;
    background: transparent;
    transition: 0.3s ease-in-out;
    color: #fff;
    margin-bottom: 20px;
    &:focus {
      border-bottom: 1px solid #fff;
    }
    &::placeholder {
      font-family: $main-font;
      color: $text-color2;
    }
  }

  .search-cross-btn {
    color: #fff;
    cursor: pointer;
    background: rgba($primary-color1, 0.6);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 41px;
    transition: 0.5s ease;

    &:hover {
      background: $primary-color1;
      color: #fff;
    }
    &.style-2 {
      background-color: transparent;
      border: 1px solid $primary-color2;
      &:hover {
        background: $primary-color2;
        color: #fff;
      }
    }
    &.style-3 {
      background-color: transparent;
      border: 1px solid $primary-color3;
      &:hover {
        background: $primary-color3;
        color: #fff;
      }
    }
  }

  .search-cross-btn i {
    font-size: 25px;
    cursor: pointer;
  }
}

/*=======================================
  3. search area end
=======================================*/

/*=======================================
   4. Topbar Css
=======================================*/
.topbar {
  background: $bg-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px;
  z-index: 999;
  position: relative;

  @include xl-device() {
    padding: 10px 30px;
  }
  @include lg-device() {
    padding: 10px 30px;
  }
  @include lg-down-device() {
    display: none;
    visibility: hidden;
  }
}
.topbar-left {
  h6 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: $text-color2;
    margin-left: 15px;
    line-height: 10px;
  }
  .topbar-social-list {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding: 0;
    margin: 0;

    li {
      a {
        color: $primary-color1;
      }
      .bx {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        line-height: 24px;
        text-align: center;
        background: transparent;
        transition: all 0.42s ease-in-out;

        &:hover {
          background: $primary-color1;
          color: $white;
        }
      }
    }
  }
}

.email-area {
  h6 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: $text-color2;
  }
}

.topbar-right-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  li {
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    transition: all 0.35s ease;
    font-size: 14px;
    font-weight: 400;
    color: $text-color2;
    font-family: $main-font;
    span,
    a {
      font-size: 14px;
      font-weight: 400;
      color: $text-color2;
      transition: all 0.35s ease;
      font-family: $main-font;
    }
    &:hover {
      color: $primary-color1;
      span,
      a {
        color: inherit;
        font-family: $main-font;
      }
      .topbar-sublist {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
      }
    }
    > img {
      margin-left: 15px;
      width: 20px;
      height: 20px;
      margin-top: 2px;
    }
    .topbar-sublist {
      position: absolute;
      top: 30px;
      left: -10px;
      list-style: none;
      padding: 0;
      margin: 0;
      background: $bg-light;
      min-width: 100px;
      height: auto;
      z-index: 9;
      border: 1px solid rgba(#fff, 0.1);
      opacity: 0;
      visibility: hidden;
      transform: translateY(5px);
      transition: all 0.6s ease;
      text-align: right;
      li {
        padding: 8px 12px;
        img {
          margin-left: 15px;
          width: 20px;
        }
      }
    }
  }
}
.nice-select {
  text-align: right !important;
  &::after {
    left: 12px !important;
  }
  .option {
    text-align: right;
  }
}
.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 95%;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}

// header

.mobile-menu-form {
  margin-top: 50px;
  input {
    width: 100%;
    border: 1px solid $border-color;
    padding: 10px 15px;
    background-color: $white;
    margin-bottom: 15px;
    outline: none;
    border-radius: 5px;
    &::placeholder {
      font-size: 12px;
    }
  }
  button {
    outline: none;
    border: none;
    padding: 10px;
  }
  &.style-2 {
    input {
      border-radius: 30px;
    }
  }
}

.hotline-info {
  text-align: center;
  h6 {
    font-size: 20px;
    font-weight: 700;
  }
}

header.style-1 {
  background-color: $white;
  width: 100%;
  position: relative;
  top: 0px;
  z-index: 99;
  padding: 0px 0px 0px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include xl-device() {
    padding: 0px 0px 0px 30px;
  }
  @include lg-device() {
    padding: 0px 0px 0px 30px;
  }
  @include lg-down-device() {
    padding: 0px 0px 0px 30px;
    top: 0;
  }
  &.sticky {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 999;
    background: $white;
    box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
    animation: smooth-header 0.65s linear;

    @keyframes smooth-header {
      0% {
        transform: translateY(-30px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  .nav-right {
    .hotline {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 40px;

      .hotline-icon {
        margin-left: 8px;
      }
      .hotline-info {
        span {
          font-size: 12px;
          font-weight: 400;
          font-family: $main-font;
          color: $text-color2;
        }
        h6 {
          font-size: 14px;
          font-weight: 600;
          font-family: $main-font;
          color: $text-color;
          white-space: nowrap;
        }
      }
    }
    .search-btn {
      width: 42px;
      height: 42px;
      line-height: 45px;
      text-align: center;
      background-color: $primary-color1;
      border-radius: 5px;
      margin-left: 15px;
      cursor: pointer;

      .bi {
        font-size: 20px;
        color: $white;
      }

      @include lg-down-device() {
        display: none;
        visibility: hidden;
      }
    }
  }
  .header-logo {
    background: $primary-color1;
    height: 100%;
    padding: 25px 60px;

    @include xl-device() {
      padding: 25px 30px;
    }
    @include xl-down-device() {
      padding: 21px 30px;
    }
    @include lg-down-device() {
      padding: 15px 30px;
    }

    img {
      min-width: 130px;
      max-width: 100%;
    }
  }
  .header-btn {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    display: inline-block;
    transition: 0.35s ease-in;
    background: $primary-color1;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      background: #191a1c;
      a {
        color: $white;
      }
    }
    @include xl-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .main-menu {
    .menu-list {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      padding: 0;
      @include lg-down-device() {
        flex-direction: column;
        justify-content: flex-start;
      }
      > li {
        position: relative;
        margin: 0px 17px;
        display: inline-block;
        @include xl-down-device() {
          margin: 0px 15px;
        }
        @include lg-down-device() {
          margin: 0;
          width: 100%;
        }
        .dropdown-icon {
          color: $text-color;
          font-size: 25px;
          transition: 0.4s ease-in;
          opacity: 0;
          visibility: hidden;
          @include lg-down-device() {
            opacity: 1;
            visibility: visible;
          }
        }

        a {
          font-size: 16px;
          font-weight: 500;
          color: $text-color;
          padding: 30px 0px;
          white-space: nowrap;
          transition: 0.4s;
          display: flex;
          align-items: center;
          font-family: $main-font;

          &.active {
            color: $primary-color1;
          }
          &:hover {
            color: $primary-color1;
          }
          @include lg-down-device() {
            padding: 12px 0px;
            display: block;
            position: relative;
            border-bottom: 1px solid rgba($text-color, 0.098);

            &:hover {
              color: unset;
            }
          }
        }

        .submenu {
          padding: 0;
          position: absolute;
          top: 84px;
          right: 0px;
          max-width: 230px;
          min-width: 200px;
          background-color: $white;
          text-align: right;
          transform: translateY(25px);
          opacity: 0;
          visibility: hidden;
          transition: all 0.55s ease-in-out;
          box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);

          @include lg-down-device() {
            opacity: 1;
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
            border: unset;
            display: none;
            transition: unset;
            box-shadow: unset;
            transform: translateY(0px);
            visibility: unset;
          }
          > li {
            a {
              display: block;
              padding: 10px 15px;
              border-bottom: 1px solid rgba($text-color, 0.1);
              position: relative;
              z-index: 1;
              font-size: 14px;
              color: $text-color;
              @include lg-down-device() {
                border-bottom: unset;
                padding: 12px 15px;
              }
              &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba($primary-color1, 1);
                width: 100%;
                height: 1px;
                transform: scaleX(0);
                transform-origin: right;
                transition: 0.4s ease-in;
                z-index: -1;
              }
              &:hover {
                color: $primary-color1;
                &::before {
                  transform: scale(1);
                }
              }
              &:last-child {
                border-bottom: unset;
              }
            }
          }
        }

        &.menu-item-has-children {
          position: relative;
          z-index: 1;
          padding-left: 20px;

          @include lg-down-device() {
            padding-right: unset;
          }
          &::after {
            content: '\F282';
            font-family: 'bootstrap-icons';
            font-weight: 600;
            position: absolute;
            top: 33px;
            left: 0px;
            font-size: 13px;
            transition: all 0.42s ease-in-out;

            @include lg-down-device() {
              display: none;
              visibility: hidden;
            }
          }
          .dropdown-icon {
            position: absolute;
            top: 12px;
            left: 0;
            font-size: 20px;
          }
          &:hover {
            .submenu {
              opacity: 1;
              transform: translateY(0);
              visibility: visible;
            }
            &::after {
              color: $primary-color1;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    @include lg-down-device() {
      position: fixed;
      top: 0%;
      right: -100%;
      width: 280px;
      height: 100%;
      overflow-y: auto;
      background-color: $white;
      padding: 40px 20px;
      z-index: 999;
      transition: 0.7s ease-in-out;
      border-right: 1px solid $border-color;

      &.show-menu {
        right: 0;
      }
    }
    .mobile-logo-area {
      margin-bottom: 25px;
      .menu-close-btn {
        cursor: pointer;
        .bi {
          color: $text-color;
          font-size: 18px;
          &:hover {
            color: $primary-color1;
          }
        }
      }
    }
    .mobile-logo-wrap {
      background: $primary-color1;
      padding: 10px;
    }
  }
}

.mobile-menu-btn {
  .bx {
    color: $text-color;
    font-size: 28px;
  }
}

// header-2
header.style-2 {
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 99;
  padding: 0px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include lg-down-device() {
    padding: 20px 30px 20px 30px;
  }
  &.sticky {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 99;
    background: $white;
    box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
    animation: smooth-header 0.65s linear;

    @keyframes smooth-header {
      0% {
        transform: translateY(-30px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  .nav-right {
    .hotline {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 50px;

      .hotline-icon {
        margin-right: 8px;
      }
      .hotline-info {
        span {
          font-size: 12px;
          font-weight: 400;
          font-family: $main-font;
          color: $text-color2;
        }
        h6 {
          font-size: 14px;
          font-weight: 600;
          font-family: $main-font;
          color: $text-color;
          white-space: nowrap;
        }
      }
    }
    .search-btn {
      width: 42px;
      height: 42px;
      line-height: 45px;
      text-align: center;
      border-radius: 5px;
      margin-left: 15px;
      cursor: pointer;
      @include lg-down-device() {
        display: none;
        visibility: hidden;
      }
      .bi {
        font-size: 20px;
        color: $primary-color2;
      }
    }
  }
  .header-logo {
    img {
      width: 100%;
      min-width: 120px;
    }
  }
  .header-btn {
    color: $white;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 100px;
    display: inline-block;
    transition: 0.35s ease-in;
    background: $primary-color2;
    cursor: pointer;
    white-space: nowrap;
    @include lg-device() {
      display: none;
      visibility: hidden;
    }
    &:hover {
      background: #191a1c;
      a {
        color: $white;
      }
    }
    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .main-menu {
    .menu-list {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      padding: 0;
      @include lg-down-device() {
        flex-direction: column;
        justify-content: flex-start;
      }
      > li {
        position: relative;
        margin: 0px 17px;
        display: inline-block;
        @include xl-down-device() {
          margin: 0px 15px;
        }
        @include lg-down-device() {
          margin: 0;
          width: 100%;
        }
        .dropdown-icon {
          color: $text-color;
          font-size: 25px;
          transition: 0.4s ease-in;
          opacity: 0;
          visibility: hidden;
          @include lg-down-device() {
            opacity: 1;
            visibility: visible;
          }
        }
        a {
          font-size: 16px;
          font-weight: 500;
          color: $text-color;
          padding: 30px 0px;
          display: inline-block;
          white-space: nowrap;
          transition: 0.4s;
          display: flex;
          align-items: center;
          font-family: $main-font;

          &.active {
            color: $primary-color2;
          }
          &:hover {
            color: $primary-color2;
          }
          @include lg-down-device() {
            padding: 12px 0px;
            display: block;
            position: relative;
            border-bottom: 1px solid rgba($text-color, 0.098);

            &:hover {
              color: unset;
            }
          }
        }

        .submenu {
          padding: 0;
          position: absolute;
          top: 84px;
          right: 0px;
          max-width: 230px;
          min-width: 200px;
          background-color: $white;
          text-align: right;
          transform: translateY(25px);
          opacity: 0;
          visibility: hidden;
          transition: all 0.55s ease-in-out;
          box-shadow: 2px 3px 20px rgba($text-color, 0.1);

          @include lg-down-device() {
            @include lg-down-device() {
              opacity: 1;
              position: relative;
              left: 0;
              top: 0;
              width: 100%;
              border: unset;
              display: none;
              transition: unset;
              box-shadow: unset;
              transform: translateY(0px);
              visibility: unset;
            }
          }
          > li {
            a {
              display: block;
              padding: 10px 15px;
              border-bottom: 1px solid rgba($text-color, 0.1);
              position: relative;
              z-index: 1;
              font-size: 14px;
              color: $text-color;
              @include lg-down-device() {
                border-bottom: unset;
                padding: 12px 15px;
              }
              &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba($primary-color2, 1);
                width: 100%;
                height: 1px;
                transform: scaleX(0);
                transform-origin: right;
                transition: 0.4s ease-in;
                z-index: -1;
              }
              &:hover {
                color: $primary-color2;
                &::before {
                  transform: scale(1);
                }
              }
              &:last-child {
                border-bottom: unset;
              }
            }
          }
        }

        &.menu-item-has-children {
          position: relative;
          z-index: 1;
          padding-left: 20px;

          @include lg-down-device() {
            padding-right: unset;
          }
          &::after {
            content: '\F282';
            font-family: 'bootstrap-icons';
            font-weight: 600;
            position: absolute;
            top: 33px;
            left: 0px;
            font-size: 13px;
            transition: all 0.42s ease-in-out;

            @include lg-down-device() {
              display: none;
              visibility: hidden;
            }
          }
          .dropdown-icon {
            position: absolute;
            top: 12px;
            left: 0;
            font-size: 20px;
          }
          &:hover {
            .submenu {
              opacity: 1;
              transform: translateY(0);
              visibility: visible;
            }
            &::after {
              color: $primary-color2;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    @include lg-down-device() {
      position: fixed;
      top: 0%;
      right: -100%;
      width: 280px;
      height: 100%;
      overflow-y: auto;
      background-color: $white;
      padding: 40px 20px;
      z-index: 999;
      transition: 0.7s ease-in-out;
      border-right: 1px solid $border-color;

      &.show-menu {
        right: 0;
      }
    }
    .mobile-logo-area {
      margin-bottom: 25px;
      .menu-close-btn {
        cursor: pointer;
        .bi {
          color: $text-color;
          font-size: 20px;
          &:hover {
            color: $primary-color2;
          }
        }
      }
    }
  }
  .join-btn {
    color: $primary-color2;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 30px;
    transition: 0.42s ease;
    white-space: nowrap;
    @include xxl-down-device() {
      display: none;
      visibility: hidden;
    }

    &:hover {
      color: $primary-color2;
    }
  }
}
header.style-3 {
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 99;
  padding: 0px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include md-down-device() {
    padding: 0px 30px;
  }

  &.sticky {
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 99;
    background: $white;
    transition: 0.2s ease;
    box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);
    animation: smooth-header 0.65s linear;

    @keyframes smooth-header {
      0% {
        transform: translateY(-30px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  .nav-right {
    .hotline {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 40px;

      .hotline-icon {
        margin-left: 8px;
      }
      .hotline-info {
        span {
          font-size: 12px;
          font-weight: 400;
          font-family: $main-font;
          color: $text-color2;
        }
        h6 {
          font-size: 14px;
          font-weight: 600;
          font-family: $main-font;
          color: $text-color;
          white-space: nowrap;
        }
      }
    }
    .search-btn {
      width: 42px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background-color: $white;
      border: 1px solid $primary-color3;
      border-radius: 50%;
      margin-left: 15px;
      cursor: pointer;

      .bi {
        font-size: 18px;
        color: $primary-color3;
      }

      @include lg-down-device() {
        display: none;
        visibility: hidden;
      }
    }
  }
  .header-logo {
    padding: 20px 0px;
    img {
      min-width: 120px;
      max-width: 100%;
    }
  }
  .header-btn {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      background: #191a1c;
      a {
        color: $white;
      }
    }
    @include xl-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .main-menu {
    .menu-list {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      padding: 0;
      @include lg-down-device() {
        flex-direction: column;
        justify-content: flex-start;
      }
      > li {
        position: relative;
        margin: 0px 17px;
        display: inline-block;
        @include xl-down-device() {
          margin: 0px 15px;
        }
        @include lg-down-device() {
          margin: 0;
          width: 100%;
        }
        .dropdown-icon {
          color: $text-color;
          font-size: 25px;
          transition: 0.4s ease-in;
          opacity: 0;
          visibility: hidden;
          @include lg-down-device() {
            opacity: 1;
            visibility: visible;
          }
        }
        a {
          font-size: 16px;
          font-weight: 500;
          color: $text-color;
          padding: 30px 0px;
          white-space: nowrap;
          transition: 0.4s;
          display: flex;
          align-items: center;
          font-family: $main-font;

          @include md-down-device() {
            &.active {
              color: $primary-color3;
            }
          }

          &:hover {
            color: $primary-color3;
          }
          @include lg-down-device() {
            padding: 12px 0px;
            display: block;
            position: relative;
            border-bottom: 1px solid rgba($text-color, 0.098);
            &:hover {
              color: unset;
            }
          }
        }

        .submenu {
          padding: 0;
          position: absolute;
          top: 84px;
          right: 0px;
          max-width: 230px;
          min-width: 200px;
          background-color: $white;
          text-align: right;
          transform: translateY(25px);
          opacity: 0;
          visibility: hidden;
          transition: all 0.55s ease-in-out;
          box-shadow: 5px 3px 40px rgba(0, 72, 88, 0.1);

          @include lg-down-device() {
            opacity: 1;
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
            border: unset;
            display: none;
            transition: unset;
            box-shadow: unset;
            transform: translateY(0px);
            visibility: unset;
          }
          > li {
            a {
              display: block;
              padding: 10px 15px;
              border-bottom: 1px solid rgba($text-color, 0.1);
              position: relative;
              z-index: 1;
              font-size: 14px;
              color: $text-color;
              @include lg-down-device() {
                border-bottom: unset;
                padding: 12px 15px;
              }
              &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba($primary-color3, 1);
                width: 100%;
                height: 1px;
                transform: scaleX(0);
                transform-origin: right;
                transition: 0.4s ease-in;
                z-index: -1;
              }
              &:hover {
                color: $primary-color3;
                &::before {
                  transform: scale(1);
                }
              }
              &:last-child {
                border-bottom: unset;
              }
            }
          }
        }

        &.menu-item-has-children {
          position: relative;
          z-index: 1;
          padding-left: 20px;

          @include lg-down-device() {
            padding-right: unset;
          }
          &::after {
            content: '\F282';
            font-family: 'bootstrap-icons';
            font-weight: 600;
            position: absolute;
            top: 33px;
            left: 0px;
            font-size: 13px;
            transition: all 0.42s ease-in-out;

            @include lg-down-device() {
              display: none;
              visibility: hidden;
            }
          }
          .dropdown-icon {
            position: absolute;
            top: 12px;
            left: 0;
            font-size: 20px;
          }
          &:hover {
            .submenu {
              opacity: 1;
              transform: translateY(0);
              visibility: visible;
            }
            &::after {
              color: $primary-color3;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    @include lg-down-device() {
      position: fixed;
      top: 0%;
      right: -100%;
      width: 280px;
      height: 100vh;
      overflow-y: auto;
      background-color: $white;
      padding: 40px 20px;
      z-index: 999;
      transition: 0.7s ease-in-out;
      border-right: 1px solid $border-color;

      &.show-menu {
        right: 0;
      }
    }
    .mobile-logo-area {
      margin-bottom: 25px;
      .menu-close-btn {
        cursor: pointer;
        .bi {
          color: $text-color;
          font-size: 20px;
          &:hover {
            color: $primary-color3;
          }
        }
      }
    }
    .mobile-logo-wrap {
      padding: 10px 0px;
    }
  }
  .mobile-menu-form {
    input[type='text'] {
      border-radius: 30px;
      color: $primary-color3;
    }
  }
}

/*=======================================
   banner-area
=======================================*/

.hero-style-one {
  position: relative;
  overflow: hidden;
  .swiper-slide-active span {
    animation: fadeInDown 1.7s;
  }
  .swiper-slide-active h1 {
    animation: fadeInDown 1.7s;
  }
  .swiper-slide-active h2 {
    animation: fadeInDown 1.7s;
  }
  .swiper-slide-active p {
    animation: fadeInUp 1.7s;
  }

  .swiper-slide-active .eg-btn {
    animation: fadeInUp 1.7s;
  }

  .hero-one-pagination {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    width: 80px;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
    .swiper-pagination-bullet {
      background: $white;
      width: 15px;
      height: 15px;
    }
    .swiper-pagination-bullet-active {
      background: $primary-color1;
    }
  }

  .slider-arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
    z-index: 2;
    padding: 5px;
  }

  .slider-bg-1 {
    padding: 160px 0px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    z-index: 1;
    position: relative;

    @include xl-device() {
      padding: 160px 0px;
    }
    @include lg-device() {
      padding: 160px 0px;
    }
    @include lg-down-device() {
      padding: 120px 0px;
    }

    &::before {
      content: '';
      background-image: url('../../../public/images/bg/banner-bg1.png');
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: -9;
      animation: large 26s linear infinite alternate;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -9;
      background: rgba(0, 0, 0, 0.55);
    }
    @keyframes large {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.6);
      }
    }
  }

  .slider-bg-2 {
    padding: 160px 0px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    z-index: 1;
    position: relative;

    @include xl-device() {
      padding: 160px 0px;
    }
    @include lg-device() {
      padding: 160px 0px;
    }
    @include lg-down-device() {
      padding: 120px 0px;
    }

    &::before {
      content: '';
      background-image: url('../../../public/images/bg/banner-bg2.png');
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: -9;
      animation: large 26s linear infinite alternate;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -9;
      background: rgba(0, 0, 0, 0.55);
    }
    @keyframes large {
      0% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1.8);
      }
    }
  }

  .banner1-content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 760px;
    span {
      font-size: 1.25rem;
      font-weight: 400;
      color: #eeeeee;
      font-family: $main-font;
      margin-bottom: 10px;
      display: block;
    }
    h1,
    h2 {
      font-size: 4.8rem;
      font-weight: 700;
      color: $white;
      margin-bottom: 15px;
      line-height: 1.2;
      text-transform: capitalize;
      @include md-device() {
        font-size: 60px;
      }
      @include md-down-device() {
        font-size: 50px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #eeeeee;
      margin-bottom: 55px;
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      @include md-device() {
        width: 95%;
      }
      @include md-down-device() {
        width: 98%;
      }
    }
  }
}

.hero-style-three {
  overflow-x: hidden;
  padding: 0px 0px;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../public/images/bg/home3-bannerbg.png');
  @include lg-down-device() {
    background-image: unset;
    padding-top: 85px;
  }
  .banner-ellipse {
    position: absolute;
    top: 30px;
    left: 0px;
    z-index: -1;
    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .join-merchant {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $primary-color3;
    border-radius: 22px 0px 0px 22px;
    color: $white;
    font-weight: 600;
    padding: 10px 20px 10px 10px;
    cursor: pointer;
    @include fifteen-down-device {
      display: none;
      visibility: hidden;
    }
  }
  &::before {
    // content: '';
    position: absolute;
    left: 0;
    top: 85px;
    bottom: 85px;
    width: 100%;
    max-width: 560px;
    height: auto;
    background: $primary-color3;
    border-radius: 0px 279px 279px 0px;
    z-index: -1;
    @include xl-device() {
      max-width: 450px;
    }
    @include lg-device() {
      max-width: 380px;
    }
    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }

  .home3-banner {
    position: absolute;
    width: 100%;
    max-width: 600px;
    height: auto;
    right: 140px;
    top: 55%;
    transform: translateY(-50%);
    z-index: -1;
    @include xl-device() {
      max-width: 600px;
    }
    @include lg-device() {
      max-width: 500px;
    }
    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .banner3-content {
    width: 100%;
    padding: 240px 60px 100px 45px;
    @include lg-down-device() {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.55)),
        url('../../../public/images/bg/inner-bg.png');
      text-align: center;
    }
    @include xl-device() {
      padding: 200px 60px 100px 0px;
    }
    @include md-device() {
      padding: 80px 60px;
    }
    @include md-down-device() {
      padding: 80px 20px;
    }
    span {
      font-size: 20px;
      font-weight: 600;
      color: $primary-color3;
      font-family: $main-font;
      margin-bottom: 10px;
      display: block;

      @include lg-down-device() {
        color: #fff;
      }
    }
    h1 {
      font-size: 50px;
      font-weight: 700;
      color: $text-color;
      margin-bottom: 20px;
      line-height: 1.2;
      @include lg-down-device() {
        color: $white;
      }

      @include md-device() {
        font-size: 60px;
      }
      @include md-down-device() {
        font-size: 50px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: $text-color2;
      margin-bottom: 45px;
      font-family: $main-font;
      @include lg-down-device() {
        color: $white;
        margin-left: auto;
        margin-right: auto;
      }
      @include md-device() {
        width: 80%;
      }
    }
  }
}

/*=======================================
   Category-Slider
=======================================*/
.category-section {
  .swiper {
    margin: -12px;
    padding: 12px;
  }
}

.category-section.taps {
  .category-card1 {
    min-width: auto;
    width: 100%;
    cursor: pointer;

    h5 {
      font-size: 18px;
    }

    .cat-icon {
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.category-card1 {
  max-width: 170px;
  min-width: 170px;
  border-radius: 40px 0px;
  text-align: center;
  padding: 20px 5px;
  margin-left: auto;
  margin-right: auto;
  background: $white;
  position: relative;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: unset;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $primary-color1;
    transform: scaleX(0);
    border-radius: 5px;
    z-index: -1;
    transform-origin: left;
    transition: transform 0.35s ease-in;
  }

  &:hover,
  &.active {
    &::before {
      transform: scaleX(1);
      transform-origin: right;
    }

    .cat-icon {
      img {
        filter: brightness(0) invert(1);
      }
    }
    h5 {
      color: $white;
    }
  }

  .cat-icon {
    margin-bottom: 15px;

    img {
      margin-bottom: 0;
      height: 60px;
      transition: all 0.35s ease-in;
    }
  }

  h5 {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 600;
    color: $text-color;
    transition: all 0.35s ease-in;
  }
  &.style2 {
    &::before {
      background: $primary-color3;
    }
  }
}

.category-prev1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;

  i {
    height: 38px;
    width: 38px;
    line-height: 38px;
    border-radius: 50%;
    background: $white;
    color: #484848;
    font-size: 16px;
    text-align: center;
    border: 1px solid #484848;
    transition: 0.5s ease;
  }

  &:hover {
    i {
      background: $primary-color1;
      border: 1px solid $primary-color1;
      color: $white;
    }
  }
  &.style2 {
    &:hover {
      i {
        background: $primary-color3;
        border: 1px solid $primary-color3;
        color: $white;
      }
    }
  }
}

.category-next1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -46px;

  i {
    height: 38px;
    width: 38px;
    line-height: 38px;
    border-radius: 50%;
    background: $white;
    color: #484848;
    font-size: 16px;
    text-align: center;
    border: 1px solid #484848;
    transition: 0.5s ease;
  }

  &:hover {
    i {
      background: $primary-color1;
      color: $white;
      border: 1px solid $primary-color1;
    }
  }
  &.style2 {
    &:hover {
      i {
        background: $primary-color3;
        border: 1px solid $primary-color3;
        color: $white;
      }
    }
  }
}

.category-prev2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -40px;
  @include xl-down-device() {
    display: none;
    visibility: hidden;
  }

  i {
    height: 38px;
    width: 38px;
    line-height: 38px;
    border-radius: 50%;
    background: $white;
    color: rgba($primary-color2, 0.6);
    font-size: 16px;
    text-align: center;
    border: 1px solid rgba($primary-color2, 0.6);
    transition: 0.5s ease;
  }

  &:hover {
    i {
      background: $primary-color2;
      border: 1px solid $primary-color2;
      color: $white;
    }
  }
}

.category-next2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -40px;

  @include xl-down-device() {
    display: none;
    visibility: hidden;
  }

  i {
    height: 38px;
    width: 38px;
    line-height: 38px;
    border-radius: 50%;
    background: $white;
    color: rgba($primary-color2, 0.6);
    font-size: 16px;
    text-align: center;
    border: 1px solid rgba($primary-color2, 0.6);
    transition: 0.5s ease;
  }

  &:hover {
    i {
      background: $primary-color2;
      border: 1px solid $primary-color2;
      color: $white;
    }
  }
}

.category-card2 {
  width: 100%;
  max-width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.4s ease;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($text-color, 0.45);
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &:hover {
    .category-img {
      transform: scale(1.2);
    }
  }

  .category-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
  }
  .content {
    text-align: center;
    img {
      margin-bottom: 15px;
      height: 60px;
    }
    h5 {
      text-align: center;
      transition: all 0.5s ease-in-out;
      margin-bottom: 0;
      a {
        transition: all 0.5s ease-in-out;
        color: $white;
      }
    }
  }
}

/*================================================
7. Live Auction
=================================================*/
.live-auction {
  position: relative;
  overflow: hidden;
  .section-bg {
    position: absolute;
    top: 7%;
    left: 0%;

    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }

  .section-bg2 {
    position: absolute;
    top: 7%;
    right: 0%;

    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .dotted1 {
    position: absolute;
    top: 58px;
    right: -80px;
    animation: move 2.6s linear infinite;

    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .dotted2 {
    position: absolute;
    bottom: 40px;
    left: -80px;
    animation: move 2.6s linear infinite;

    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .dotted3 {
    position: absolute;
    bottom: -40px;
    left: -80px;
    animation: move 2.6s linear infinite;

    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  @keyframes move {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
.auction-card1 {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  &:hover {
    .auction-img {
      img {
        transform: scale(1.2);
      }
    }
  }
  .auction-img {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 5px 5px 0px 0px;
      transition: all 0.65s ease;
    }
    .auction-timer {
      border-radius: 30px;
      padding: 12px 20px;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
      background: $white;
      min-width: 230px;

      .countdown {
        h4 {
          font-size: 24px;
          font-weight: 600;
          color: $countdown-color;
          margin-bottom: 0;

          @include xl-device() {
            font-size: 22px;
          }
          @include xl-down-device() {
            font-size: 20px;
          }
        }
      }
    }
  }
  .auction-content {
    padding: 30px 25px;
    h4 {
      font-size: 23px;
      font-weight: 600;
      color: $text-color;
      line-height: 1.4;
      margin-top: -5px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;
      span {
        font-size: 20px;
        font-weight: 700;
        color: $text-color;
      }
    }
    .price {
      font-size: 16px;
      font-weight: 700;
      color: $primary-color1;
    }
  }

  .auction-card-bttm {
    @include flex(space-between);
    margin-top: 25px;
  }

  .author-area {
    position: absolute;
    top: 30px;
    right: 30px;
    @include flex(flex-start);
    cursor: pointer;

    .author-emo {
      border-radius: 50%;
      background: $white;
      padding: 7px;
      @include flex(center);
      border: 1px solid transparent;
      z-index: 1;

      img {
        width: 22px;
        height: 22px;
        line-height: 22px;
      }
    }

    .author-name {
      background: $primary-color1;
      padding: 6px 44px 6px 20px;
      border-radius: 33px;
      margin-right: -27px;
      transform: scaleX(0.1);
      transform-origin: right;
      transition: 0.6s ease;

      span {
        font-size: 14px;
        font-weight: 600;
        color: $white;
      }
    }

    &:hover {
      .author-emo {
        border: 1px solid $primary-color1;
      }

      .author-name {
        transform: scaleX(1);
      }
    }
  }

  .share-area {
    @include flex(flex-end);
    .share-btn {
      i {
        height: 34px;
        width: 34px;
        line-height: 34px;
        border-radius: 50%;
        background-color: $border-color;
        text-align: center;
        transition: 0.5s ease;
        z-index: 9;

        &:hover {
          background: $text-color;
          color: $white;
        }
      }
    }

    .social-icons {
      padding: 5px;
      margin-right: 0px;
      transform: scaleX(0);
      transform-origin: left;
      transition: 0.5s ease;
      z-index: 1;
      margin-bottom: 0px;
    }

    &:hover {
      .social-icons {
        transform: scaleX(1);

        li {
          margin-right: 8px;

          i {
            font-size: 1rem;
            transition: all 0.42s ease;
            &:hover {
              color: $primary-color1;
            }
          }
        }
      }
    }
  }
}

.auction-card2 {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 15px 15px 0px 15px;
  &:hover {
    .auction-img {
      img {
        transform: scale(1.2);
        border-radius: 10px;
      }
      .auction-timer {
        .countdown {
          h5 {
            color: $countdown-color;
          }
        }
      }
    }
  }
  .auction-img {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 10px;
      transition: all 0.65s ease;
    }

    .auction-timer {
      border-radius: 15px 15px 0px 0px;
      padding: 12px 15px;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
      background: $white;
      min-width: 230px;

      .countdown {
        h5 {
          font-size: 20px;
          font-weight: 700;
          color: $countdown-color;
          margin-bottom: 0;
          white-space: nowrap;
          transition: all 0.42s ease;
        }
      }
    }
  }

  .auction-content {
    padding: 30px 25px;

    h4 {
      font-size: 23px;
      font-weight: 600;
      color: $text-color;
      line-height: 1.5;
      margin-top: -7px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;
      span {
        font-size: 20px;
        font-weight: bold;
        color: $text-color;
      }
    }

    .price {
      font-size: 16px;
      font-weight: 700;
      color: $primary-color1;
    }
  }

  .auction-card-bttm {
    @include flex(space-between);
    margin-top: 20px;
    .share-area {
      i {
        color: $text-color2;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        &:hover {
          color: $primary-color2;
        }
      }
    }
  }
  .author-price-area {
    @include flex(space-between);
    margin-top: 15px;
    .author {
      img {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        margin-left: 10px;
      }
      span.name {
        font-size: 14px;
        font-weight: 600;
        color: $text-color2;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 700;
      color: $primary-color2;
      font-family: $main-font;
    }
  }

  .author-area {
    position: absolute;
    top: 20px;
    left: 20px;
    @include flex(flex-start);
    cursor: pointer;

    .author-emo {
      border-radius: 50%;
      background: $white;
      padding: 7px;
      @include flex(center);
      border: 1px solid transparent;
      z-index: 1;

      img {
        width: 22px;
        height: 22px;
        line-height: 22px;
      }
    }

    .author-name {
      background: $primary-color1;
      padding: 6px 20px 6px 44px;
      border-radius: 33px;
      margin-left: -27px;
      transform: scaleX(0.1);
      transform-origin: left;
      transition: 0.6s ease;

      span {
        font-size: 14px;
        font-weight: 600;
        color: $white;
      }
    }

    &:hover {
      .author-emo {
        border: 1px solid $primary-color1;
      }

      .author-name {
        transform: scaleX(1);
      }
    }
  }

  .share-list {
    @include flex(center);
    padding-left: 0px;
    .social-icons {
      padding: 5px;
      margin-right: 0px;
      transform: scaleX(0);
      transform-origin: right;
      transition: 0.5s ease;
      z-index: 1;
      margin-bottom: 0px;
    }

    &:hover {
      .social-icons {
        transform: scaleX(1);

        li {
          margin-right: 8px;

          i {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
.auction-card3 {
  padding: 20px 20px 25px 20px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  box-shadow: 2px 2px 20px rgb(0 0 0 / 21%);
  border-radius: 0px 30px;
  text-align: center;
  border: unset;

  &:hover {
    .auction-img {
      img {
        transform: scale(1.2);
      }
    }
  }
  .auction-timer {
    border-radius: 30px;
    padding-bottom: 20px;
    text-align: center;
    background: $white;
    span.timer-title {
      font-size: 14px;
      font-weight: 400;
      font-family: $main-font;
      color: $text-color2;
      margin-bottom: 5px;
      display: block;
    }

    .countdown {
      h4 {
        font-size: 22px;
        font-weight: 700;
        color: $primary-color3;
        margin-bottom: 0;

        @include xl-device() {
          font-size: 22px;
        }
        @include xl-down-device() {
          font-size: 20px;
        }
      }
    }
  }

  .auction-img {
    position: relative;
    overflow: hidden;
    border-radius: 0px 30px;
    margin-bottom: 25px;

    img {
      max-height: 200px;
      display: block;
      margin: auto;
      transition: all 0.65s ease;
    }
  }

  .auction-content {
    h4 {
      font-size: 23px;
      font-weight: bolder;
      color: $text-color;
      line-height: 1.5;
      margin-top: -7px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;
      span {
        font-size: 20px;
        font-weight: bold;
        color: $text-color;
      }
    }

    .price {
      font-size: 16px;
      font-weight: 700;
      color: $primary-color1;
    }
  }

  .auction-card-bttm {
    @include flex(space-between);
    margin-top: 20px;
  }

  .author-area {
    position: absolute;
    top: 20px;
    right: 20px;
    @include flex(flex-start);
    cursor: pointer;

    .author-emo {
      border-radius: 50%;
      background: $white;
      padding: 7px;
      @include flex(center);
      border: 1px solid transparent;
      z-index: 1;

      img {
        width: 22px;
        height: 22px;
        line-height: 22px;
      }
    }

    .author-name {
      background: $primary-color3;
      padding: 6px 44px 6px 20px;
      border-radius: 33px;
      margin-right: -27px;
      transform: scaleX(0.1);
      transform-origin: right;
      transition: 0.6s ease;

      span {
        font-size: 14px;
        font-weight: 600;
        color: $white;
      }
    }

    &:hover {
      .author-emo {
        border: 1px solid $primary-color1;
      }

      .author-name {
        transform: scaleX(1);
      }
    }
  }

  .share-area {
    @include flex(flex-end);
    .share-btn {
      i {
        height: 34px;
        width: 34px;
        line-height: 34px;
        border-radius: 50%;
        background-color: $border-color;
        text-align: center;
        transition: 0.5s ease;
        z-index: 9;

        &:hover {
          background: $text-color;
          color: $white;
        }
      }
    }

    .social-icons {
      padding: 5px;
      margin-left: 0px;
      transform: scaleX(0);
      transform-origin: left;
      transition: 0.5s ease;
      z-index: 1;
      margin-bottom: 0px;
    }

    &:hover {
      .social-icons {
        transform: scaleX(1);

        li {
          margin-right: 8px;

          i {
            font-size: 1rem;
            transition: all 0.42s ease;
            &:hover {
              color: $primary-color1;
            }
          }
        }
      }
    }
  }
  &.style-2 {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    .auction-timer {
      .countdown {
        h4 {
          color: $countdown-color;
        }
      }
    }
  }
}
/*================================================
7. Upcoming Auction
=================================================*/
.slider-bottom {
  margin-top: 60px;
  .coming-next1 {
    .bi {
      padding: 8px 10px;
      line-height: 45px;
      border-radius: 50%;
      background: $white;
      color: #dddddd;
      font-size: 16px;
      text-align: center;
      border: 1px solid #dddddd;
      transition: 0.5s ease;
    }
    &:hover {
      i {
        background: $primary-color1;
        color: $white;
        border: 1px solid $primary-color1;
      }
    }
  }
  .coming-prev1 {
    i {
      padding: 8px 10px;

      line-height: 45px;
      border-radius: 50%;
      background: $white;
      color: #dddddd;
      font-size: 16px;
      text-align: center;
      border: 1px solid #dddddd;
      transition: 0.5s ease;
    }
    &:hover {
      i {
        background: $primary-color1;
        color: $white;
        border: 1px solid $primary-color1;
      }
    }
  }
  .coming-next2 {
    .bi {
      padding: 8px 10px;
      line-height: 45px;
      border-radius: 50%;
      background: $white;
      color: #dddddd;
      font-size: 16px;
      text-align: center;
      border: 1px solid #dddddd;
      transition: 0.5s ease;
    }
    &:hover {
      i {
        background: $primary-color2;
        color: $white;
        border: 1px solid $primary-color2;
      }
    }
  }
  .coming-prev2 {
    i {
      padding: 8px 10px;

      line-height: 45px;
      border-radius: 50%;
      background: $white;
      color: #dddddd;
      font-size: 16px;
      text-align: center;
      border: 1px solid #dddddd;
      transition: 0.5s ease;
    }
    &:hover {
      i {
        background: $primary-color2;
        color: $white;
        border: 1px solid $primary-color2;
      }
    }
  }
  .coming-next3 {
    .bi {
      padding: 8px 10px;
      line-height: 45px;
      border-radius: 50%;
      background: $white;
      color: #dddddd;
      font-size: 16px;
      text-align: center;
      border: 1px solid #dddddd;
      transition: 0.5s ease;
    }
    &:hover {
      i {
        background: $primary-color3;
        color: $white;
        border: 1px solid $primary-color3;
      }
    }
  }
  .coming-prev3 {
    i {
      padding: 8px 10px;

      line-height: 45px;
      border-radius: 50%;
      background: $white;
      color: #dddddd;
      font-size: 16px;
      text-align: center;
      border: 1px solid #dddddd;
      transition: 0.5s ease;
    }
    &:hover {
      i {
        background: $primary-color3;
        color: $white;
        border: 1px solid $primary-color3;
      }
    }
  }
  .coming-arrow {
    min-width: 105px;
    svg {
      fill: $border-color;
    }
  }
  .swiper-pagination {
    position: unset;
    width: 60%;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -20px;
      height: 2px;
      max-width: 330px;
      width: 100%;
      background: $border-color;

      @include xxl-down-device() {
        display: none;
        visibility: hidden;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -20px;
      height: 2px;
      max-width: 330px;
      width: 100%;
      background: $border-color;
      @include xxl-down-device() {
        display: none;
        visibility: hidden;
      }
    }
    .swiper-pagination-bullet {
      width: 10px;
      height: 8px;
      border-radius: 50px;
      background: $text-color2;
      transition: all 0.42s ease;
      &.swiper-pagination-bullet-active {
        background: $primary-color1;
        width: 15px;
      }
    }
    &.style-3 {
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background-color: $primary-color3;
        }
      }
    }
  }
}
.upcoming-seciton {
  position: relative;
  .section-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}
.c-feature-card1 {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    .auction-timer2 {
      .countdown-single {
        border: 1.5px solid $primary-color1;
      }
    }
    .auction-img {
      > img {
        transform: scale(1.12);
      }
    }
  }
  .auction-img {
    position: relative;
    overflow: hidden;
    height: 350px;
    img {
      width: 100%;
      transition: all 0.55s ease-in-out;
    }
  }
  .c-feature-content {
    padding: 30px 25px;
    h4 {
      font-size: 23px;
      font-weight: 600;
      color: $text-color;
      line-height: 1.4;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;

      span {
        font-size: 20px;
        font-weight: bold;
        color: $text-color;
      }
    }
    .price {
      font-size: 16px;
      font-weight: 700;
      color: $text-color2;
    }
    .auction-card-bttm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      .share-area {
        @include flex(flex-end);
        .share-btn {
          i {
            height: 34px;
            width: 34px;
            line-height: 34px;
            border-radius: 50%;
            background-color: $border-color;
            text-align: center;
            transition: 0.5s ease;
            z-index: 9;

            &:hover {
              background: $text-color;
              color: $white;
            }
          }
        }

        .social-icons {
          margin-left: 10px;
          transform: scaleX(0);
          transform-origin: left;
          transition: 0.5s ease;
          z-index: 1;
          padding: 0;
        }

        &:hover {
          .social-icons {
            transform: scaleX(1);

            li {
              margin-right: 8px;

              i {
                font-size: 1rem;
                transition: all 0.42s ease;

                &:hover {
                  color: $primary-color1;
                }
              }
            }
          }
        }
      }
    }

    .c-feature-category {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;
      font-family: $main-font;
      margin-bottom: 15px;
      margin-top: -5px;
    }
  }
  .auction-timer2 {
    padding: 15px;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    background: $text-color;
    min-width: 220px;
    @include flex(center);
    .countdown-single {
      border: 1.5px solid $text-color2;
      border-radius: 10px 0;
      padding: 10px 5px;
      transition: all 0.5s ease;

      @include xl-device() {
        padding: 8px 3px;
      }
      @include md-down-device() {
        padding: 6px 0px;
      }

      h5 {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 700;
        color: $white !important;
        line-height: 1;
      }
      span {
        color: $white;
        border-radius: 3px;
        margin: 10px;
        padding: 5px;
        min-width: 50px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .author-area2 {
    position: absolute;
    top: 20px;
    right: 20px;
    @include flex(flex-start);
    cursor: pointer;

    .author-emo {
      border-radius: 50%;

      @include flex(center);
      border: 1px solid transparent;
      z-index: 1;

      img {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
      }
    }

    .author-name {
      background: $white;
      padding: 6px 44px 6px 20px;
      border-radius: 33px;
      margin-right: -28px;
      transform: scaleX(0.1);
      transform-origin: right;
      transition: 0.6s ease;

      span {
        font-size: 14px;
        font-weight: 600;
        color: $text-color;
      }
    }

    &:hover {
      .author-emo {
        border: 1px solid transparent;
      }

      .author-name {
        transform: scaleX(1);
      }
    }
  }
}
.c-feature-card2 {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    .auction-timer2 {
      .countdown-single {
        border: 1.5px solid $primary-color2;
      }
    }
    .auction-img {
      > img {
        transform: scale(1.12);
      }
    }
  }

  .auction-img {
    position: relative;
    overflow: hidden;
    height: 350px;
    img {
      width: 100%;
      transition: all 0.55s ease-in-out;
    }
  }
  .c-feature-content {
    padding: 30px 25px;

    h4 {
      font-size: 23px;
      font-weight: 700;
      color: $text-color;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;

      span {
        font-size: 20px;
        font-weight: bold;
        color: $primary-color2;
      }
    }

    .price {
      font-size: 16px;
      font-weight: 700;
      color: $text-color2;
    }
    .auction-card-bttm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      .share-area {
        @include flex(flex-end);
        .share-btn {
          i {
            height: 34px;
            width: 34px;
            line-height: 34px;
            border-radius: 50%;
            background-color: $border-color;
            text-align: center;
            transition: 0.5s ease;
            z-index: 9;

            &:hover {
              background: $text-color;
              color: $white;
            }
          }
        }

        .social-icons {
          transform: scaleX(0);
          transform-origin: left;
          transition: 0.5s ease;
          z-index: 1;
          margin: 0;
          padding: 0;

          li {
            a {
              .bx {
                transition: all 0.5s ease-out 0s;
              }
              &:hover {
                .bx {
                  color: $primary-color2;
                }
              }
            }
          }
        }

        &:hover {
          .social-icons {
            transform: scaleX(1);

            li {
              margin-left: 8px;

              i {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
    .c-feature-category {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;
      font-family: $main-font;
      margin-bottom: 15px;
      margin-top: -5px;
    }
  }

  .auction-timer2 {
    padding: 15px;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    background: $text-color;
    min-width: 220px;
    @include flex(center);

    .countdown-single {
      border: 1.5px solid $text-color2;
      border-radius: 10px;
      padding: 10px 5px;
      transition: all 0.5s ease;
      @include lg-down-device() {
        padding: 8px 0px;
      }

      h5 {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 700;
        color: $white !important;
        line-height: 1;
      }
      span {
        color: $white;
        border-radius: 3px;
        margin: 10px;
        padding: 5px;
        min-width: 50px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .author-area2 {
    position: absolute;
    top: 20px;
    right: 20px;
    @include flex(flex-start);
    cursor: pointer;

    .author-emo {
      border-radius: 50%;

      @include flex(center);
      border: 1px solid transparent;
      z-index: 1;

      img {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
      }
    }

    .author-name {
      background: $white;
      padding: 6px 44px 6px 20px;
      border-radius: 33px;
      margin-right: -28px;
      transform: scaleX(0.1);
      transform-origin: right;
      transition: 0.6s ease;

      span {
        font-size: 14px;
        font-weight: 600;
        color: $text-color;
      }
    }

    &:hover {
      .author-emo {
        border: 1px solid transparent;
      }

      .author-name {
        transform: scaleX(1);
      }
    }
  }
  .author-area3 {
    position: absolute;
    top: 20px;
    right: 20px;
    @include flex(flex-start);
    cursor: pointer;

    .author-emo {
      border-radius: 50%;

      @include flex(center);
      border: 1px solid transparent;
      z-index: 1;

      img {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
      }
    }

    .author-name {
      background: $white;
      padding: 6px 44px 6px 20px;
      border-radius: 33px;
      margin-right: -28px;
      transform: scaleX(0.1);
      transform-origin: right;
      transition: 0.6s ease;

      span {
        font-size: 14px;
        font-weight: 600;
        color: $text-color;
      }
    }

    &:hover {
      .author-emo {
        border: 1px solid transparent;
      }

      .author-name {
        transform: scaleX(1);
      }
    }
  }
}

/*================================================
7. Testimonial
=================================================*/
.testi-next2,
.testi-next1 {
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  @include md-down-device() {
    display: none;
    right: -30px;
    visibility: hidden;
  }
  @include xl-down-device() {
    right: -40px;
  }

  .bi {
    padding: 8px 10px;
    line-height: 45px;
    border-radius: 50%;
    background: $white;
    color: #666666;
    font-size: 16px;
    text-align: center;
    border: 1px solid #666666;
    transition: 0.5s ease;
  }

  &:hover {
    i {
      background: $primary-color1;
      color: $white;
      border: 1px solid $primary-color1;
    }
  }
  &.style-2 {
    i {
      border: 1px solid $border-color;
    }

    &:hover {
      i {
        background: $primary-color2;
        color: $white;
        border: 1px solid $primary-color2;
      }
    }
  }
  &.style-3 {
    i {
      border: 1px solid $border-color;
    }

    &:hover {
      i {
        background: $primary-color3;
        color: $white;
        border: 1px solid $primary-color3;
      }
    }
  }
}
.testi-prev2,
.testi-prev1 {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  @include md-down-device() {
    display: none;
    left: -30px;
    visibility: hidden;
  }
  @include xl-down-device() {
    left: -40px;
  }

  i {
    padding: 8px 10px;
    line-height: 45px;
    border-radius: 50%;
    background: $white;
    color: #666666;
    font-size: 16px;
    text-align: center;
    border: 1px solid #666666;
    transition: 0.5s ease;
  }

  &:hover {
    i {
      background: $primary-color1;
      color: $white;
      border: 1px solid $primary-color1;
    }
  }
  &.style-2 {
    i {
      border: 1px solid $border-color;
    }

    &:hover {
      i {
        background: $primary-color2;
        color: $white;
        border: 1px solid $primary-color2;
      }
    }
  }
  &.style-3 {
    i {
      border: 1px solid $border-color;
    }

    &:hover {
      i {
        background: $primary-color3;
        color: $white;
        border: 1px solid $primary-color3;
      }
    }
  }
}

.testimonial-section {
  background-image: url('../../../public/images/bg/client-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include md-down-device() {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .swiper {
    margin: -25px -15px;
    padding: 25px 15px;
  }
  .client-right-vector {
    position: absolute;
    top: 0;
    right: 0;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .client-left-vector {
    position: absolute;
    bottom: 0;
    left: 0;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }

  .client-circle1 {
    position: absolute;
    right: 14%;
    bottom: 30px;
    animation: fluid 5s linear infinite alternate;
  }
  .client-circle2 {
    position: absolute;
    left: 8%;
    bottom: 30px;
    animation: fluid 5s linear infinite alternate;
    animation-delay: 2s;
  }
  .client-circle3 {
    position: absolute;
    left: 30%;
    bottom: 30px;
    animation: fluid 5s linear infinite alternate;
    animation-delay: 2s;
  }
  .client-circle4 {
    position: absolute;
    left: 8%;
    bottom: 30px;
    animation: fluid 5s linear infinite alternate;
    animation-delay: 2s;
  }
  .client-circle5 {
    position: absolute;
    right: 14%;
    bottom: 30px;
    animation: fluid 5s linear infinite alternate;
  }
  .swiper {
    margin: -15px;
    padding: 15px;
  }
}
@keyframes fluid {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-25%);
  }
  50% {
    transform: translateY(-50%);
  }
  75% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.testimonial-single {
  background: $white;
  box-shadow: 5px 2px 15px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  padding: 25px;
  transition: 0.42s ease;
  transition-delay: 0.3s;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  p {
    color: $text-color;
    font-size: 16px;
  }
  .quote-icon {
    position: absolute;
    bottom: 30px;
    right: 20px;
  }

  .testi-img {
    width: 60px;
    height: 60px;
    margin-top: -50px;
    margin-bottom: 20px;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      max-width: 100%;
      border-radius: 50%;
    }
  }
  .testi-content {
    .testi-designation {
      margin-top: 21px;
      h5 {
        font-size: 18px;
        font-weight: 600;
        color: $text-color;
        line-height: 1;
        margin-bottom: 5px;
      }
      p {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
        color: $text-color2;
        font-style: normal;
      }
    }
  }
  &.style2 {
    position: relative;
    .testi-designation {
      margin-bottom: 20px;
    }
    .testi-content {
      .para {
        margin-bottom: 0;
      }
    }
    .quote-icon {
      position: absolute;
      top: 30px;
      left: 20px;
    }
    .testi-img {
      width: 60px;
      height: 60px;
      margin-top: unset;
      margin-bottom: 15px;
      img {
        max-width: 100%;
        border-radius: 50%;
      }
    }
  }
}
.testimonial-slider {
  padding-top: 40px !important;
}
/*================================================
7. Blog 
=================================================*/

.recent-news-section {
  position: relative;
  overflow: hidden;

  .section-bg {
    position: absolute;
    right: -100px;
    bottom: 10%;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg2 {
    position: absolute;
    right: 0px;
    top: 0px;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .dot-circle {
    position: absolute;
    top: 240px;
    right: 250px;
    animation: round 8s linear infinite;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  @keyframes round {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.single-blog-style1 {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  &:hover {
    .blog-img {
      img {
        transform: scale(1.12);
      }
    }
  }
  .blog-img {
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
      transition: all 0.7s ease;
      border-radius: 5px 5px 0px 0px;
    }

    .blog-date {
      position: absolute;
      right: 0;
      top: 30px;
      padding: 5px 20px;
      font-size: 16px;
      font-weight: 500;
      font-family: $main-font;
      color: $white;
      background: $primary-color1;
      border-radius: 17px 0px 0px 17px;
      z-index: 1;

      .bi {
        margin-left: 10px;
        font-size: 16px;
      }
    }
  }
  .blog-content {
    padding: 30px 25px;
    background: $white;
    h5 {
      a {
        font-size: 25px;
        font-weight: 600;
        font-family: $main-font;
        color: $text-color;
        line-height: 1.4;
        transition: all 0.42s ease-in-out;
      }
      &:hover {
        a {
          color: $primary-color1;
        }
      }
    }
  }
  .blog-meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
    // @include md-down-device(){
    //     justify-content: space-between;
    // }

    .author {
      margin-left: 35px;
      img {
        width: 100%;
        max-width: 26px;
        border-radius: 50%;
        margin-left: 8px;
      }
      .author-name {
        font-size: 14px;
        font-weight: 500;
        font-family: $main-font;
        color: $text-color2;
        white-space: nowrap;
        @include lg-down-device() {
          font-size: 12px;
        }
      }
    }
    .comment {
      img {
        margin-left: 8px;
      }
      .comment {
        font-size: 14px;
        font-weight: 500;
        font-family: $main-font;
        color: $text-color2;
        white-space: nowrap;
      }
    }
  }
}
.single-blog-style2 {
  position: relative;
  &:hover {
    .blog-content {
      transform: translateY(-20px);
    }
  }
  .blog-img {
    img {
      max-width: 100%;
      transition: all 0.7s ease;
      border-radius: 5px 5px 0px 0px;
    }

    .blog-date {
      position: absolute;
      right: 0;
      top: 30px;
      padding: 5px 20px;
      font-size: 16px;
      font-weight: 500;
      font-family: $main-font;
      color: $white;
      background: $primary-color2;
      border-radius: 17px 0px 0px 17px;
      z-index: 1;

      .bi {
        margin-left: 10px;
        font-size: 16px;
      }
    }
  }
  .blog-content {
    position: relative;
    width: 94%;
    padding: 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    background: $white;
    z-index: 9;
    box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background: $white;
    transition: all 0.55s ease-in-out;
    margin-top: -50px;
    @include md-down-device() {
      padding: 20px;
    }

    h5 {
      a {
        font-size: 22px;
        font-weight: 600;
        font-family: $main-font;
        color: $text-color;
        line-height: 1.4;
        transition: all 0.42s ease-in-out;
      }
      &:hover {
        a {
          color: $primary-color2;
        }
      }
    }
    p {
      margin-bottom: 0px;
    }
  }
  .blog-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    @include md-down-device() {
      justify-content: space-between;
    }

    .author {
      margin-left: 45px;
      img {
        width: 100%;
        max-width: 26px;
        border-radius: 50%;
        margin-left: 8px;
      }
      .author-name {
        font-size: 14px;
        font-weight: 500;
        font-family: $main-font;
        color: $text-color2;
        white-space: nowrap;
      }
    }
    .comment {
      img {
        margin-left: 8px;
      }
      .comment {
        font-size: 14px;
        font-weight: 500;
        font-family: $main-font;
        color: $text-color2;
        white-space: nowrap;
      }
    }
  }
}
// blog-style-3
.single-blog-style3 {
  position: relative;
  &:hover {
    .blog-content {
      transform: translateY(-20px);
    }
  }
  .blog-img {
    img {
      height: 300px;
      width: 100%;
      max-width: 100%;
      transition: all 0.7s ease;
      border-radius: 5px 5px 0px 0px;
    }

    .blog-date {
      position: absolute;
      right: 0;
      top: 30px;
      padding: 5px 20px;
      font-size: 16px;
      font-weight: 500;
      font-family: $main-font;
      color: $white;
      background: $primary-color3;
      border-radius: 17px 0px 0px 17px;
      z-index: 1;
      .bi {
        margin-left: 10px;
        font-size: 16px;
      }
    }
  }
  .blog-content {
    position: relative;
    width: 94%;
    padding: 25px 20px;
    margin-left: auto;
    margin-right: auto;
    background: $white;
    z-index: 9;
    box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background: $white;
    transition: all 0.55s ease-in-out;
    margin-top: -50px;
    @include md-down-device() {
      padding: 20px;
    }

    h5 {
      a {
        font-size: 22px;
        font-weight: 600;
        font-family: $main-font;
        color: $text-color;
        line-height: 1.4;
        transition: all 0.42s ease-in-out;
      }
      &:hover {
        a {
          color: $primary-color3;
        }
      }
    }
    p {
      margin-bottom: 0px;
    }
  }
  .blog-meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    @include md-down-device() {
      justify-content: space-between;
    }
    .author {
      margin-left: 35px;
      img {
        width: 100%;
        max-width: 26px;
        border-radius: 50%;
        margin-left: 5px;
      }
      .author-name {
        font-size: 12px;
        font-weight: 500;
        font-family: $main-font;
        color: $text-color2;
        white-space: nowrap;
      }
    }
    .comment {
      img {
        margin-left: 8px;
      }
      .comment {
        font-size: 12px;
        font-weight: 500;
        font-family: $main-font;
        color: $text-color2;
        white-space: nowrap;
      }
    }
  }
}

/*================================================
7. about-us counter 
=================================================*/

.counter-single {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 30px 25px;
  @include sm-down-device() {
    padding: 20px 15px;
  }

  .counter-icon {
    margin-right: 15px;
  }
  .coundown {
    h3 {
      font-size: 30px;
      font-weight: 600;
      color: $text-color;
      position: relative;
      padding-right: 15px;
      margin: 0 auto !important;

      &::after {
        content: '+';
        position: absolute;
        right: 0;
        top: -2px;
      }
    }
    p {
      margin-bottom: 0px;
      font-weight: 500;
      color: $text-color2;
      font-size: 16px;
    }
  }
}

/*================================================
    Sponsor-section
=================================================*/
.sponsor-section {
  overflow: hidden;
  padding: 80px 0px 100px 0px;

  &.style-1 {
    padding: 120px 0px;
    @include md-down-device() {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &.style-2 {
    .slick-dots {
      .slick-active {
        background-color: $primary-color2;
      }
    }
  }
  .slick-list {
    margin: -15px;
  }
  .slick-slide > div {
    padding: 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slide-item {
    img {
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      max-width: 100%;
      transition: all 0.5s ease-out 0s;
    }

    &:hover {
      img {
        transform: translateY(-5px);
      }
    }
  }
  .slick-dots {
    position: absolute;
    bottom: -60px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
      background: $text-color2;
      width: 10px;
      height: 8px;
      border-radius: 50px;
    }
  }
  .slick-dots li button:before {
    opacity: 0;
  }
  .slick-dots {
    .slick-active {
      background-color: $primary-color3;
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 45px;
  }
  .slick-wrapper {
    direction: ltr;
  }
}

/*================================================
7. Footer
=================================================*/

footer {
  background-image: url('../../../public/images/bg/footer-map.png'),
    linear-gradient(203deg, #291fbc 0%, #171835 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .footer-social {
    display: flex;
    gap: 20px;
    align-items: center;

    a {
      color: $primary-color1;
      font-size: 1.2rem;
      width: 40px;
      height: 40px;
      line-height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #fff;
      border-radius: 8px;
      transition: 0.4s;
      background-color: #fff;

      &:hover {
        border-color: $primary-color1;
      }
    }
  }

  .footer-top {
    padding: 90px 0px;

    @include lg-down-device() {
      padding: 80px 0px;
    }
    @include md-down-device() {
      padding: 60px 0px;
    }
  }
  background-color: $primary-color1;
  position: relative;

  .footer-item {
    min-width: 200px;
    a {
      img {
        margin-bottom: 25px;
        filter: brightness(0) invert(1);
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: $text-color-light;
      margin-bottom: 25px;
      font-family: $main-font;
    }
    .input-with-btn {
      max-width: 350px;
      width: 100%;
      border: 1px solid $text-color2;
      padding: 5px;
      border-radius: 5px;

      input {
        width: 100%;
        border-radius: 5px;
        background: transparent;
        border: none;
        outline: none;
        padding: 7px 20px;
        transition: all 0.4s ease;
        font-size: 14px;
        font-weight: 400;
        color: $white;

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: $text-color2;
        }
      }
      button {
        outline: none;
        border: none;
        background: $primary-color1;
        border-radius: 5px;
        min-width: 46px;
        min-height: 44px;

        img {
          transition: 0.65s ease;
        }
        &:hover {
          img {
            transform: rotate(360deg);
          }
        }
      }
    }
    h5 {
      font-size: 22px;
      font-weight: 600;
      color: $white;
      margin-bottom: 25px;
    }
    .footer-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 15px;
        position: relative;
        padding-right: 0px;
        transition: all 0.4s ease-in;

        &::before {
          content: '';
          position: absolute;
          top: 10px;
          right: 0;
          border-radius: 50%;
          height: 6px;
          width: 6px;
          background: $primary-color1;
          opacity: 0;
          transition: all 0.4s ease;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          padding-right: 12px;
          a {
            color: $primary-color1;
          }

          &::before {
            opacity: 1;
          }
        }
        a {
          color: $text-color-light;
          font-size: 16px;
          font-weight: 500;
          font-family: $main-font;
          transition: all 0.4s ease;
        }
      }
    }
  }
  .footer-bottom {
    // background: #000000;
    padding: 15px 0px;
    border-top: 1px solid #2f2f2f;

    p {
      font-size: 14px;
      font-weight: 500;
      font-family: $main-font;
      color: $white;
      margin-bottom: 0;
      margin-left: 30px;
      @include sm-down-device() {
        margin-right: 0px;
      }
      .egns-lab {
        font-weight: 600;
        color: $primary-color1;
      }
    }

    .footer-logo-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-flex;

      li {
        margin-right: 20px;
        img {
          transition: all 0.6s ease;
        }
        &:last-child {
          margin-left: 0px;
        }

        &:hover {
          img {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
ul.recent-feed-list {
  margin: 0;
  padding: 0;
  list-style: none;

  .single-feed {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0px;
    }

    .feed-img {
      margin-left: 15px;
      img {
        min-width: 64px;
        height: 64px;
        border-radius: 5px;
        margin-bottom: 0;
      }
    }
    .feed-content {
      span {
        font-size: 14px;
        font-weight: 400;
        color: rgba($white, 0.8);
        font-family: $main-font;
        display: block;
        margin-bottom: 5px;
      }
      h6 {
        margin-bottom: 0;
        a {
          font-size: 16px;
          font-weight: 600;
          color: $text-color-light;
          transition: all 0.42s ease-in;
        }
        &:hover {
          a {
            color: $primary-color1;
          }
        }
      }
    }
  }
}
ul.recent-post {
  margin: 0;
  padding: 0;
  list-style: none;

  .single-post {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0px;
    }

    .post-img {
      margin-left: 15px;
      img {
        min-width: 64px;
        height: 64px;
        border-radius: 5px;
        margin-bottom: 0;
      }
    }
    .post-content {
      span {
        font-size: 14px;
        font-weight: 400;
        color: $text-color2;
        font-family: $main-font;
        display: block;
        margin-bottom: 5px;
      }
      h6 {
        margin-bottom: 0;
        a {
          font-size: 16px;
          font-weight: 600;
          color: $text-color;
          transition: all 0.42s ease-in;
        }
        &:hover {
          a {
            color: $primary-color1;
          }
        }
      }
    }
  }
}
ul.category-list {
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
    a {
      font-size: 16px;
      font-weight: 500;
      font-family: $main-font;
      color: $text-color2;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        &:first-child {
          padding-right: 20px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 9px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $primary-color1;
            transition: all 0.5s ease-out 0s;
          }
        }
        &:last-child {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          color: $white;
          background: $primary-color1;
          border: 1px solid $primary-color1;
          border-radius: 50%;
          transition: all 0.5s ease-out 0s;
        }
      }
      &:hover {
        span {
          &:first-child {
            &::before {
              background: $text-color2;
            }
          }
          &:last-child {
            color: $primary-color1;
            background: $white;
            border: 1px solid $primary-color1;
          }
        }
      }
    }
  }
}
.sidebar-social-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  li {
    .bx {
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid $border-color;
      color: $text-color2;
      border-radius: 5px;
      transition: all 0.5s ease-out 0s;
      &:hover {
        border: 1px solid $primary-color1;
        color: $primary-color1;
      }
    }
  }
}

footer.style-2 {
  background-image: url('../../../public/images/bg/footer2-bg.png');
  position: relative;
  .footer-top {
    padding: 90px 0px;
  }

  .footer-item {
    min-width: 200px;
    a {
      img {
        margin-bottom: 25px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: $text-color-light;
      margin-bottom: 25px;
      font-family: $main-font;
    }
    .input-with-btn {
      max-width: 350px;
      width: 100%;
      border: 1px solid $text-color2;
      padding: 5px;
      border-radius: 30px;

      input {
        width: 100%;
        border-radius: 30px;
        background: transparent;
        border: none;
        outline: none;
        padding: 7px 20px;
        transition: all 0.4s ease;
        font-size: 14px;
        font-weight: 400;
        color: $white;

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: $text-color2;
        }
      }
      button {
        outline: none;
        border: none;
        background: $primary-color2;
        border-radius: 30px;
        min-width: 120px;
        min-height: 46px;
        font-size: 16px;
        font-weight: 600;
        font-family: $main-font;
        color: $white;
        transition: all 0.42s ease;

        &:hover {
          background: rgba($primary-color2, 0.9);
        }

        img {
          transition: 0.65s ease;
        }
        &:hover {
          img {
            transform: rotate(360deg);
          }
        }
      }
    }
    h5 {
      font-size: 22px;
      font-weight: 600;
      color: $white;
      margin-bottom: 25px;
    }
    .footer-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 0px;
        transition: all 0.4s ease-in;

        &::before {
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          border-radius: 50%;
          height: 6px;
          width: 6px;
          background: $primary-color2;
          opacity: 0;
          transition: all 0.4s ease;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          padding-left: 12px;
          a {
            color: $primary-color2;
          }

          &::before {
            opacity: 1;
          }
        }
        a {
          color: $text-color-light;
          font-size: 16px;
          font-weight: 500;
          font-family: $main-font;
          transition: all 0.4s ease;
        }
      }
    }
    .address-list {
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0px;
        }
        a {
          font-size: 16px;
          font-weight: 500;
          font-family: $main-font;
          color: $text-color-light;
          transition: all 0.42s ease;
        }
        &:hover {
          a {
            color: $primary-color2;
          }
        }
      }
    }
    .footer-social {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;

      i {
        color: $white;
        width: 34px;
        height: 34px;
        line-height: 34px;
        border: 1px solid rgba($white, 0.5);
        text-align: center;
        transition: all 0.42s ease;

        &:hover {
          background: $primary-color2;
          border: 1px solid $primary-color2;
        }
      }
    }
  }
  .footer-bottom {
    background: #000000;
    padding: 15px 0px;

    p {
      font-size: 14px;
      font-weight: 500;
      font-family: $main-font;
      color: $white;
      margin-bottom: 0;
      margin-left: 30px;

      .egns-lab {
        font-weight: 600;
        color: $primary-color2;
      }
    }

    .footer-logo-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-flex;

      li {
        margin-right: 20px;
        img {
          transition: all 0.6s ease;
        }
        &:last-child {
          margin-left: 0px;
        }

        &:hover {
          img {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

footer.style-3 {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url('../../../public/images/bg/footer3-bg.png');
  position: relative;
  .footer-top {
    padding: 90px 0px;
  }

  .footer-item {
    min-width: 200px;
    a {
      img {
        margin-bottom: 25px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: $text-color-light;
      margin-bottom: 25px;
      font-family: $main-font;
    }
    .input-with-btn {
      max-width: 350px;
      width: 100%;
      border: 1px solid $text-color2;
      padding: 5px;
      border-radius: 30px;

      input {
        width: 100%;
        border-radius: 30px;
        background: transparent;
        border: none;
        outline: none;
        padding: 7px 20px;
        transition: all 0.4s ease;
        font-size: 14px;
        font-weight: 400;
        color: $white;

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: $text-color2;
        }
      }
      button {
        outline: none;
        border: none;
        background: $primary-color3;
        border-radius: 30px;
        min-width: 120px;
        min-height: 46px;
        font-size: 16px;
        font-weight: 600;
        font-family: $main-font;
        color: $white;
        transition: all 0.42s ease;

        &:hover {
          background: rgba($primary-color3, 0.9);
        }

        img {
          transition: 0.65s ease;
        }
        &:hover {
          img {
            transform: rotate(360deg);
          }
        }
      }
    }
    h5 {
      font-size: 22px;
      font-weight: 600;
      color: $white;
      margin-bottom: 25px;
    }
    .footer-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 0px;
        transition: all 0.4s ease-in;

        &::before {
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          border-radius: 50%;
          height: 6px;
          width: 6px;
          background: $primary-color3;
          opacity: 0;
          transition: all 0.4s ease;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          padding-left: 12px;
          a {
            color: $primary-color3;
          }

          &::before {
            opacity: 1;
          }
        }
        a {
          color: $text-color-light;
          font-size: 16px;
          font-weight: 500;
          font-family: $main-font;
          transition: all 0.4s ease;
        }
      }
    }
    .address-list {
      margin: 0;
      padding: 0;
      li {
        margin-bottom: 15px;
        a {
          font-size: 16px;
          font-weight: 500;
          font-family: $main-font;
          color: $text-color-light;
          transition: all 0.42s ease;
        }
        &:hover {
          a {
            color: $primary-color3;
          }
        }
      }
    }
    .footer-social {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;

      i {
        color: $white;
        width: 34px;
        height: 34px;
        line-height: 34px;
        border: 1px solid rgba($white, 0.5);
        text-align: center;
        transition: all 0.42s ease;

        &:hover {
          background: $primary-color3;
          border: 1px solid $primary-color3;
        }
      }
    }
  }
  .footer-bottom {
    background: #000000;
    padding: 15px 0px;

    p {
      font-size: 14px;
      font-weight: 500;
      font-family: $main-font;
      color: $white;
      margin-bottom: 0;
      margin-left: 30px;

      .egns-lab {
        font-weight: 600;
        color: $primary-color3;
      }
    }

    .footer-logo-list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-flex;

      li {
        margin-left: 20px;
        img {
          transition: all 0.6s ease;
        }
        &:last-child {
          margin-left: 0px;
        }

        &:hover {
          img {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

/*================================================
7. Blog page
=================================================*/

.blog-section {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}

.pagination-wrap {
  .page-item {
    &.active {
      .page-link {
        z-index: 3;
        color: $primary-color1 !important;
        background-color: $white !important;
        border-color: $primary-color1;
      }
    }
  }
  .pagination {
    margin: 0;
    &.style-two {
      .page-link {
        position: relative;
        display: block;
        color: $text-color;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 2px;
        transition: 0.42s ease;
        padding: 10px;

        &:focus {
          color: $primary-color1;
          border-color: $primary-color1;
          background-color: $white;
        }

        &:hover {
          color: $primary-color1;
          border-color: $primary-color1;
        }
      }
    }
  }

  .page-item {
    &:first-child {
      .page-link {
        border-radius: 2px;
      }
    }
    &:last-child {
      .page-link {
        border-radius: 2px;
      }
    }
  }

  .page-link {
    position: relative;
    display: block;
    color: $text-color;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 8px 8px 2px 8px;
    transition: 0.42s ease;

    &:focus {
      box-shadow: unset;
      color: $white;
      background-color: $primary-color1;
      border-color: $primary-color1;
    }

    &:hover {
      color: $white;
      background-color: $primary-color1;
      border-color: $primary-color1;
    }
  }
  .page-item.active .page-link {
    z-index: 3;
    color: $white;
    background-color: $primary-color1;
    border-color: $primary-color1;
  }
}

.inner-banner {
  min-height: 400px;
  background: #171835;
  position: relative;
  margin-top: 88px;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &::before {
    content: url('../../../public/images/bg/inner-bg.png');
    background-position: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }

  .breadcrumb {
    justify-content: center;
  }
}

.breadcrumb {
  .breadcrumb-item {
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    font-family: $main-font;
    &.active {
      color: #999;
    }
  }
}

.inner-banner-title {
  font-size: 65px;
  font-weight: 700;
  color: #fff;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: rgb(108, 117, 125);
  content: var(--bs-breadcrumb-divider, '/');
}

/*================================================
7. Login Page
=================================================*/

.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: rgba($primary-color1, 0.3);
}
.nice-select .option:hover {
  background-color: rgba($primary-color1, 0.3);
}

.nice-select.open,
.nice-select:focus {
  border-color: $primary-color1;
}
.nice-select {
  width: 100%;
  height: 50px;
  line-height: 50px;
  float: unset;
  .list {
    width: 100%;
  }
}

.login-section {
  position: relative;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}

.form-wrapper {
  border-radius: 5px;
  box-shadow: 5px 7px 35px 18px rgba(139, 139, 139, 0.1);
  padding: 40px;
  background: $white;

  @include sm-down-device {
    padding: 25px;
  }
  .form-title {
    text-align: center;
    margin-bottom: 60px;
    h3 {
      font-size: 30px;
      font-weight: 700;
      color: $text-color;
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: $text-color;

      a {
        font-weight: 600;
        font-size: 20px;
        text-transform: capitalize;
        color: $text-color;
      }
    }
  }
  .form-title2 {
    margin-bottom: 30px;
    h3 {
      font-size: 35px;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 15px;
    }
  }

  label {
    color: $text-color;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
    font-family: $main-font;
  }
  input,
  textarea {
    border: 1px solid #eeeeee;
    height: 50px;
    width: 100%;
    padding: 10px 20px;
    transition: 0.4s ease;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
    }

    &:focus {
      border: 1px solid $primary-color1;
      border-radius: 5px;
    }
  }
  textarea {
    min-height: 150px;
  }
  .check-box-text {
    font-size: 16px;
  }
  .form-inner {
    margin-bottom: 18px;
    position: relative;
    i {
      position: absolute;
      left: 20px;
      bottom: 14px;
      cursor: pointer;
    }
  }

  .form-agreement {
    margin-bottom: 16px;
    margin-top: 6px;
    label {
      font-size: 16px;
      font-weight: 400;
      a {
        font-weight: 600;
        font-size: 16;
      }
    }
  }
  .forgotpass {
    text-align: center;
    margin-top: 25px;
  }

  .forgot-pass {
    color: $text-color;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    transition: 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 15px;
      background: $text-color;
      height: 1px;
      width: 100%;
      @include sm-down-device {
        display: none;
        visibility: hidden;
      }
    }
    &:hover {
      color: #999;
      &::after {
        background: #999;
      }
    }
  }
  .account-btn {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background: $primary-color1;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 15px;
    outline: none;
    border: 1px solid $primary-color1;
    border-radius: 5px;
    transition: 0.4s ease;
    &:hover {
      background: #fff;
      color: $primary-color1;
    }
  }
  .alternate-signup-box {
    border: 1px solid #eeeeee;
    border-radius: 3px;
    padding: 20px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 35px;

    h6 {
      font-size: 16px;
      font-weight: 700;
      color: $text-color;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    .btn-group {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;

      a {
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
        padding: 12px 15px;
        color: #fff;
        border-radius: 5px;
        transition: 0.5s ease;

        &:hover {
          background: $text-color;
        }
      }
      i {
        font-size: 16px;
      }
    }
    .google-btn {
      background: #ea4335;
    }
    .facebook-btn {
      background: #4267b2;
    }
  }
  .form-poicy-area {
    text-align: center;
    a {
      font-weight: 600;
      color: $primary-color1;
      text-decoration: underline;
    }
  }
  // login-page check-box
  .form-group input[type='checkbox'] {
    display: none;
    cursor: pointer;
  }

  input[type='checkbox'] + label:before {
    content: '';
    -webkit-appearance: none;
    background-color: $text-color;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 5px;
  }

  input[type='checkbox']:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    right: 6px;
    width: 4px;
    height: 10px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  button.form--btn {
    outline: none;
    border: none;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 25px;
  }
}

.login-section {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: -80px;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}

.signup-section {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: -80px;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}

/*================================================
7. How Work Page
=================================================*/

.how-work-section {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: -80px;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .how-work-content {
    span {
      font-size: 50px;
      font-weight: 700;
      color: $primary-color1;
      font-family: $main-font;
      display: block;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 30px;
      font-weight: 700;
      color: $text-color;
    }
    a {
      margin-top: 25px;
    }
  }
  .how-work-img {
    background: $white;
    box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    width: 100%;
    max-width: 505px;
    height: 505px;

    @include lg-device() {
      box-shadow: unset;
    }
    @include md-down-device() {
      box-shadow: unset;
      max-width: 100%;
      height: auto;
    }

    @include flex(center);

    .work-img {
      max-width: 100%;
      animation: jumping 4s linear infinite alternate;
    }

    @keyframes jumping {
      0% {
        transform: translateY(0);
      }
      25% {
        transform: translateY(-10px);
      }
      50% {
        transform: translateY(0px);
      }
      75% {
        transform: translateY(10px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}

.single-feature {
  border: 1px solid $border-color;
  background: $white;
  padding: 30px;
  text-align: center;
  position: relative;
  transition: all 0.42s ease;

  &:hover {
    box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    border: 1px solid transparent;

    .sn {
      -webkit-text-stroke-color: rgba($text-color, 1);
    }
    .icon {
      svg {
        fill: $primary-color1;
      }
    }
  }

  .sn {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 50px;
    font-weight: 700;
    color: rgba($text-color, 0.1);
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba($text-color, 0.1);
    line-height: 1;
    transition: all 0.42s ease;
  }
  .icon {
    margin-bottom: 20px;

    svg {
      fill: $text-color2;
      transition: all 0.42s ease;
    }
  }

  .content {
    h5 {
      a {
        font-size: 22px;
        font-weight: 700;
        color: $text-color;
        transition: all 0.42s ease-in;
        cursor: unset;

        &:hover {
          color: $primary-color1;
        }
      }
    }
  }
}

.choose-us-section {
  position: relative;
  overflow: hidden;

  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}

/*================================================
FAQ Page
=================================================*/

.faq-wrap {
  .accordion-item {
    margin-bottom: 24px;
    border: none;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-button {
    font-weight: 500;
    font-size: 20px;
    background: $white;
    border-radius: 5px;
    color: $text-color;
    padding: 25px 25px 25px 60px;
    padding-left: 60px;
    position: relative;
    transition: 0.4s ease-in;
    box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    text-align: right;
    line-height: 1.4;

    @include md-down-device() {
      padding: 25px 25px 25px 60px;
    }

    &:hover {
      &::after {
        background: $primary-color1;
        color: #fff;
      }
    }

    &:focus {
      z-index: unset;
      border-color: unset;
      outline: 0;
      background: $white;
      box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    }

    &::after {
      flex-shrink: 0;
      width: unset;
      height: unset;
      margin-left: auto;
      background-image: none;
      background-repeat: unset;
      background-size: unset;
      font-family: bootstrap-icons !important;
      font-size: 15px;
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
      content: '\F282';
      transition: unset;
      font-size: 20px;
      color: $primary-color1;
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $primary-color1;
      transition: all 0.4s ease;
    }

    &:not(.collapsed)::after {
      background-image: none;
      transform: unset;
      font-family: bootstrap-icons !important;
      content: '\F286';
      background: $primary-color1;
      color: $white;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .accordion-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    font-family: $main-font;
    background: rgba($primary-color1, 0.05);
    border-top: none;
    text-align: right;
  }
  .accordion-button:not(.collapsed) {
    box-shadow: unset;
    color: $text-color;
    border-radius: 5px 5px 0px 0px;
    box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    background: $white;
  }
}
.faq-form-area {
  margin-top: 78px;
  padding: 25px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  @include lg-down-device() {
    margin-top: unset;
  }
  h5 {
    font-size: 25px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
  }
  .faq-form {
    label {
      margin-bottom: 15px;
      font-size: 1rem;
      font-weight: 500;
      color: $text-color;
      font-family: $main-font;
      line-height: 1;
    }
    .form-inner {
      margin-bottom: 20px;
    }
    input,
    textarea {
      width: 100%;
      padding: 12px 20px;
      border: 1px solid #eeeeee;
      transition: 0.35s ease-in;

      @include md-down-device() {
        padding: 10px 15px;
      }

      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: $text-color2;
        font-family: $main-font;
      }
      &:focus {
        border: 1px solid $primary-color1;
      }
    }
  }
}
.section-title3 {
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 600;
  color: $text-color;
  text-align: right;
}

/*================================================
live-auction Page
=================================================*/

.live-auction-section {
  position: relative;
  z-index: 1;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}

/*================================================
 Auction-details Page
=================================================*/

.auction-details-section {
  position: relative;
  .nav {
    padding-right: 0px;
  }
  .section-bg-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .details-tab-btn {
    background: $white;
  }
}
.product-details-right {
  h3 {
    font-size: 30px;
    font-weight: 700;
    font-family: $main-font;
    color: $text-color;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
  }
  h4 {
    font-size: 25px;
    font-weight: 400;
    color: $text-color;
    span {
      font-weight: 700;
    }
  }
  .bid-form {
    margin-top: 40px;
    box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background: $white;
    padding: 25px;

    .form-title {
      padding-bottom: 3px;
      margin-bottom: 20px;
      h5 {
        font-size: 22px;
        font-weight: 600;
        color: $text-color;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        color: $text-color2;
        font-family: $main-font;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          right: 0;
          bottom: -12px;
          width: 52px;
          height: 3px;
          border-radius: 5px;
          background-color: $primary-color1;
        }
        &::after {
          content: '';
          position: absolute;
          right: 56px;
          bottom: -12px;
          width: 4px;
          height: 3px;
          border-radius: 5px;
          background-color: $primary-color1;
        }
      }
    }

    .form-inner {
      display: flex;
      justify-content: center;
      align-items: center;

      input[type='text'] {
        width: 100%;
        border-radius: 5px;
        border: 1px solid $border-color;
        padding: 11px 15px;
        transition: all 0.42s ease;

        &::placeholder {
          font-size: 16px;
          font-weight: 500;
          color: $text-color2;
        }

        &:focus {
          border: 1px solid $primary-color1;
        }
      }
      button {
        outline: none;
        border: none;
        padding: 10px 35px;
        font-size: 18px;
        font-weight: 700;
        font-family: $main-font;
      }
    }
  }
}
.small-image-list {
  padding-right: 0px;
  .nav-item {
    img {
      max-width: 120px;
      width: auto;
      border-radius: 5px;
      height: 120px;
      cursor: pointer;
      transition: all 0.62s ease;

      @include xl-device() {
        height: 115px;
      }
      @include lg-device() {
        height: 112px;
      }
      @include md-down-device() {
        height: 100px;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.tab-content {
  img {
    // width: 100%;
    border-radius: 0px 0px 5px 5px;
  }
  .auction-gallery-timer {
    background-color: $text-color;
    border-radius: 5px 4px 0px 0px;
    text-align: center;
    padding: 10px 20px;

    h3 {
      font-size: 2rem;
      font-weight: 600;
      color: $white;
      margin-bottom: 0;
      @include md-down-device() {
        font-size: 24px;
      }
    }
  }
}

// auction-details-tab
.details-tab-btn {
  background: #ffffff;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  color: $text-color;
  font-size: 18px;
  font-weight: 600;
  font-family: $main-font;
  width: 100%;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-out 0s;

  &:hover {
    background: $primary-color1;
    color: #fff;
  }

  &.active {
    background: $primary-color1 !important;
    color: #fff;
  }
}

.bid-list {
  padding-left: 0;
  li {
    border-bottom: 1px solid #eeeeee;
    padding: 20px 0px;
    &:last-child {
      border-bottom: unset;
      padding-bottom: 0px;
    }
  }
  .bidder-area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .bidder-img {
      margin-left: 25px;
      @include md-down-device() {
        margin-right: 15px;
      }
    }
    .bidder-content {
      h6 {
        font-size: 18px;
        font-weight: 600;
        color: $text-color;
        margin-bottom: 3px;
        font-family: $main-font;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        color: $text-color2;
        font-family: $main-font;
        margin-bottom: 0;
      }
    }
  }
  .bid-time {
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      font-family: $main-font;
      color: $text-color2;
    }
  }
}
.bid-list-area {
  max-height: 350px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 5px 5px;
  padding: 0px 20px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    transition: 0.35s ease-in;
    border-radius: 5px;
    height: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $text-color;
  }
}
.describe-content {
  h4 {
    font-size: 24px;
    font-weight: 600;
    font-family: $main-font;
    color: $text-color;
  }
  p {
    margin-bottom: 20px;
  }
  .describe-list {
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 15px;
      position: relative;
      padding-left: 30px;

      &::before {
        content: '\F269';
        font-family: 'bootstrap-icons';
        font-weight: 600;
        position: absolute;
        right: auto;
        left: 0;
        top: 0px;
        font-size: 20px;
      }
      a {
        font-size: 18px;
        font-weight: 500;
        font-family: $main-font;
        color: $text-color2;
        line-height: 1.5;
      }
      .bx {
        font-size: 24px;
        margin-right: 12px;
      }
    }
  }
}
.offer-banner {
  padding: 25px;
  border-radius: 5px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 15px;
  }

  img {
    width: 100%;
  }
}

/*================================================
7.  about
=================================================*/

.about-section {
  position: relative;
  .section-bg-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .about-content {
    span {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;
      text-transform: uppercase;
      margin-bottom: 10px;
      display: block;
    }
    h2 {
      font-size: 40px;
      font-weight: 700;
      color: $text-color;
      margin-bottom: 15px;
    }
    .about-list {
      margin-top: 25px;
    }
  }
}

// .certificate-section {
//   background-image: url('../../../public/images/bg/frappe-certificate-bg.png');
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
//   padding: 50px 0;
// }

.about-list {
  margin: 0;
  padding: 0;
  margin-bottom: 40px;

  li {
    margin-bottom: 15px;
    position: relative;
    padding-right: 30px;

    &::before {
      content: '\F269';
      font-family: 'bootstrap-icons';
      font-weight: 600;
      position: absolute;
      right: 0;
      top: 0px;
      font-size: 20px;
    }
    a {
      font-size: 18px;
      font-weight: 500;
      font-family: $main-font;
      color: $text-color2;
      line-height: 1.5;
    }
    .bx {
      font-size: 24px;
      margin-right: 12px;
    }
  }
}

.about-img-area {
  position: relative;
  .total-tag {
    position: absolute;
    right: 30px;
    top: 0;
    background: #ffffff;
    box-shadow: 3px 2px 35px rgba(0, 27, 85, 0.08);
    border-radius: 100px 100px 100px 5px;
    padding: 25px;
    text-align: center;
    @include xl-down-device() {
      right: 0px;
    }
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
    img {
      margin-bottom: 12px;
      max-width: 100%;
    }
    h6 {
      font-size: 16px;
      font-weight: 500;
      color: $text-color;
    }
    h5 {
      font-size: 20px;
      font-weight: 700;
      color: $text-color;
    }
  }
  .about-vector {
    position: absolute;
    left: -50px;
    top: 0;
    z-index: -1;
    animation: rotation 14s linear infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .about-linear {
    position: absolute;
    bottom: -70px;
    left: 0;

    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}
.choose-us-section {
  .angle-vector {
    position: absolute;
    left: -20px;
    top: 130px;

    @include lg-device() {
      top: 150px;
      left: -10px;
    }
    @include lg-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}

/*================================================
 Contact page
=================================================*/

.map-area {
  iframe {
    width: 100%;
    height: 585px;
  }
}
.contact-section {
  position: relative;
  z-index: 1;
  .section-bg-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}
.contact-signle {
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.06);
  border: unset;
  text-align: center;
  padding: 25px;
  transition: all 0.4s ease-in;

  &:hover {
    .icon {
      background: $primary-color1;
      i {
        color: $white;
      }
    }
  }

  .icon {
    background: rgba($primary-color1, 0.2);
    height: 70px;
    min-width: 70px;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    transition: all 0.4s ease-in;
    margin-right: 15px;

    i {
      color: $primary-color1;
      transition: all 0.35s ease-in;
      font-size: 36px;
    }
  }
  .text {
    text-align: start;
    h4 {
      font-size: 25px;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 10px;
    }
    p {
      font-size: 1rem;
      font-weight: 500;
      color: $text-color2;
      font-family: $main-font;
      margin-bottom: 0px;
      line-height: 28px;
    }
    a {
      font-size: 16px;
      font-weight: 500;
      font-family: $main-font;
      color: $text-color2;
      line-height: 28px;
    }
  }
}

/*================================================
 Dashboard-page
=================================================*/
.text-red {
  color: $primary-color2 !important;
}
.text-green {
  color: $primary-color1 !important;
}
.dashboard-section {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: -100px;
    z-index: -2;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -2;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .nav-pills .nav-link {
    background-color: #fff;
    &:hover {
      background-color: $primary-color1;
    }
  }
}
.table-title-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
  h3 {
    font-size: 30px;
    font-weight: 600;
    color: $text-color;
    line-height: 1.7;
  }

  .nice-select {
    max-width: 270px;
  }
}

.eg-table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;

  thead {
    background: #f1f1f1;
    tr {
      border-width: 1px;
      th {
        font-size: 1rem;
        font-weight: 500;
        color: $text-color;
        opacity: 1;
        padding: 15px;
        vertical-align: top;
        text-align: center;
        border-width: 1px;
        border-color: rgba($text-color-light, 0.4);
        font-family: $main-font;
        white-space: nowrap;
        @include md-down-device {
          display: none;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        font-weight: 400;
        color: $text-color2;
        text-align: center;
        border: 2px solid rgba($border-color, 0.75);
        vertical-align: middle;
        font-family: $main-font;
        @include md-down-device {
          display: block;
          width: 100%;
          text-align: right;
          padding-left: 50%;
          position: relative;
        }
        img {
          max-width: 40px;
          width: 100%;
        }
      }
      @include md-down-device {
        display: block;
        width: 100%;
        margin-bottom: 30px;
      }
    }
    @include md-down-device {
      display: block;
      width: 100%;
    }
  }
  @include md-down-device {
    display: block;
    width: 100%;
  }
  td {
    @include md-down-device {
      &::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        text-align: left;
        padding-left: 15px;
        font-weight: 600;
        color: $text-color;
      }
    }
  }
}
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 30px;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  background-color: $white;
  @include md-down-device() {
    box-shadow: unset;
  }

  .order-table {
    tr {
      td {
        padding: 10px 15px;
      }
    }
  }
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  P {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
    color: $text-color;
    margin-right: 10px;
    @include sm-down-device() {
      margin-bottom: 1rem;
    }
  }
}

.nav-btn-style {
  background: $white;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  width: 100%;
  min-width: 0px;
  color: $text-color2;
  font-size: 20px;
  font-weight: 500;
  color: $text-color2;
  font-family: $main-font;
  padding: 10px 20px;
  text-align: right;
  transition: all 0.42s ease;

  svg {
    margin-left: 10px;
    fill: $text-color2;
    transition: all 0.42s ease;
  }
  &:hover {
    background-color: $primary-color1;
    color: $white;
    svg {
      fill: $white;
    }
  }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: $primary-color1;
  svg {
    fill: $white;
  }
}

.dashboard-profile {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  background-color: $white;

  .form-wrapper {
    box-shadow: unset;
  }
  .owner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 40px 40px 0px 40px;

    .image {
      margin-left: 25px;
      img {
        max-width: 100px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .content {
      h3 {
        font-size: 30px;
        font-weight: 600;
        color: $text-color;
        margin-bottom: 5px;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        color: $text-color2;
        font-family: $main-font;
        margin-bottom: 0px;
      }
    }
  }
}
.dashboard-card {
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  &:hover {
    .body {
      .icon {
        svg {
          fill: $primary-color1;
        }
      }
    }
  }
  .header {
    background: #1f2230;
    border-radius: 4px 4px 0px 0px;
    padding: 10px 20px;
    h5 {
      font-size: 18px;
      font-weight: 600;
      color: $white;
      margin-bottom: 0;
    }
  }
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 25px;
    .counter-item {
      h2 {
        font-size: 40px;
        font-weight: 600;
        color: $text-color;
      }
    }
    .icon {
      svg {
        fill: $text-color2;
        transition: all 0.42s ease;
      }
    }
  }
}
.button-group {
  margin-top: 50px;
  .profile-btn {
    border-radius: 30px;
    border: none;
    outline: none;
    padding: 13px 20px;
    background-color: $primary-color1;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    font-family: $main-font;
    transition: all 0.42s ease-in-out;
    border: 1px solid $primary-color1;
    margin-left: 20px;
    text-align: center;
    &:hover {
      background-color: $white;
      color: $primary-color1;
    }
  }
  .cancel-btn {
    border-radius: 30px;
    border: none;
    outline: none;
    padding: 13px 30px;
    color: $text-color;
    background-color: $white;
    border: 1px solid $border-color;
    transition: all 0.42s ease-in-out;
    text-align: center;

    &:hover {
      background-color: $primary-color1;
      color: $white;
      border: 1px solid $primary-color1;
    }
  }
}

/*================================================
  Blog details page
=================================================*/

.blog-details-section {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -2;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
}
// blog-details

.blog-details-single {
  .blog-img {
    img {
      width: 100%;
    }
  }

  .blog-meta {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 35px;
    margin-bottom: 25px;

    @include md-down-device() {
      justify-content: space-between;
    }
    li {
      margin-left: 20px;
    }
    a {
      font-size: 16px;
      font-weight: 500;
      color: $text-color2;
      font-family: $main-font;
      img {
        margin-left: 10px;
      }
    }
  }
  .blog-title {
    font-family: $main-font;
    font-size: 35px;
    font-weight: 600;
    line-height: 1.3;
    text-align: right;
  }
  blockquote {
    background-color: #f1f1f1;
    padding: 30px 25px;
    border-radius: 5px;
    margin-top: 25px;
    margin-bottom: 30px;
    .quote-icon {
      margin-bottom: 15px;
    }

    h5 {
      font-size: 22px;
      font-weight: 600;
      color: $text-color;
    }
  }
  .sub-title {
    font-size: 24px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 10px;
  }
  .blog-video-area {
    margin-top: 25px;
    margin-bottom: 25px;

    img {
      border-radius: 5px;
    }
  }
  .blog-tag {
    border-bottom: 1px solid $border-color;
    padding: 20px 0px;
    margin-top: 35px;
    margin-bottom: 50px;
    h6 {
      font-size: 16px;
      font-weight: 400;
      color: $text-color2;
      margin-bottom: 0px;
      margin-left: 10px;
      white-space: nowrap;
    }
    .tag-list {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      font-weight: 600;

      li {
        margin-right: 5px;
        text-decoration: underline;
        a {
          font-size: 12px;
          font-weight: 500;
          font-family: $main-font;
          color: $text-color2;
          transition: all 0.42s ease;
          white-space: nowrap;
          &:hover {
            color: $primary-color1;
          }
        }
      }
    }
    .share-social {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .bx {
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        text-align: center;
        transition: all 0.42s ease-out 0s;

        &:hover {
          background-color: $primary-color1;
          color: $white;
        }
      }
    }
  }
  .blog-author {
    background: #f1f1f1;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 50px;
    .author-image {
      img {
        min-width: 140px;
      }
    }
    .author-detials {
      text-align: right;
      h5 {
        font-size: 22px;
        font-weight: 600;
        color: $text-color;
        margin-bottom: 10px;
      }
    }
  }
}

// video-popup
.video-play {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  .video-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    line-height: 52px;
    display: inline-block;
    text-align: center;
    position: relative;
    border: 1px solid #fff;
    font-size: 36px;
    color: $primary-color1;
    background: #fff;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
      -webkit-animation: ripple-1 2s infinite ease-in-out;
      animation: ripple-1 2s infinite ease-in-out;
      z-index: -1;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.3);
      z-index: -1;
      -webkit-animation: ripple-2 2s infinite ease-in-out;
      animation: ripple-2 2s infinite ease-in-out;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }
  }
}
@keyframes ripple-1 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.7);
    transform: scale(1.7);
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.9);
    transform: scale(1.9);
    opacity: 0;
  }
}

.blog-widget-title {
  margin-bottom: 30px;
  h4 {
    font-size: 25px;
    font-weight: 600;
    font-family: $main-font;
  }
  span {
    width: 50px;
    height: 3px;
    background: $primary-color1;
    border-radius: 5px;
    display: block;
    position: relative;
    margin-top: 12px;

    &::before {
      content: '';
      width: 5px;
      height: 3px;
      background: $primary-color1;
      border-radius: 5px;
      position: absolute;
      left: -10px;
      top: 0;
    }
    &::after {
      content: '';
      width: 5px;
      height: 3px;
      background: $primary-color1;
      border-radius: 5px;
      position: absolute;
      left: -20px;
      top: 0;
    }
  }
  &.style2 {
    h4 {
      margin-bottom: 0;
    }
  }
}
.sidebar-widget-title {
  margin-bottom: 25px;

  h4 {
    font-size: 25px;
    font-weight: 600;
    font-family: $main-font;
  }
  span {
    width: 50px;
    height: 3px;
    background: $primary-color1;
    border-radius: 5px;
    display: block;
    position: relative;
    margin-top: 5px;

    &::before {
      content: '';
      width: 5px;
      height: 3px;
      background: $primary-color1;
      border-radius: 5px;
      position: absolute;
      right: -10px;
      top: 0;
    }

    &::after {
      content: '';
      width: 5px;
      height: 3px;
      background: $primary-color1;
      border-radius: 5px;
      position: absolute;
      right: -20px;
      top: 0;
    }
  }
}
.comment-list {
  padding: 0;
  margin: 0;
  > li {
    border-bottom: 1px solid $border-color;
    margin-bottom: 30px;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }
  }
}
.blog-sidebar {
  .sidebar-banner {
    background-image: linear-gradient(
        90deg,
        rgba($text-color, 0.75),
        rgba($text-color, 0.75)
      ),
      url('../../../public/images/blog/banner-card-img.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;
    object-fit: cover;
    padding: 25px;
    min-height: 415px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    span {
      font-size: 18px;
      font-weight: 500;
      color: $white;
      display: block;
      margin-bottom: 5px;
    }
    h3 {
      font-size: 30px;
      font-weight: 600;
      color: $white;
      font-family: $main-font;
      line-height: 1.4;
      margin-bottom: 30px;
    }
    .card--btn {
      padding: 9px 35px;
      font-size: 18px;
      font-weight: 600;
      color: $white;
    }
  }
}

.comment-box {
  .author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 100%;
      max-width: 50px;
      border-radius: 50%;
      margin-left: 15px;
    }
    h5 {
      font-size: 20px;
      font-weight: 600;
      color: $text-color;
      margin-bottom: 0px;
    }
    span.commnt-date {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: -4px;
      margin-right: 10px;
      color: $text-color2;
      font-family: $main-font;
    }
  }
  .comment-header {
    margin-bottom: 10px;
    .commnt-reply {
      min-width: 25px;
      border: 1px solid $border-color;
      border-radius: 50%;
      text-align: center;
      transition: all 0.42s ease;
      .bi {
        color: $text-color2;
        transition: all 0.42s ease;
      }
      &:hover {
        border: 1px solid $primary-color1;
        .bi {
          color: $primary-color1;
          transition: all 0.42s ease;
        }
      }
    }
  }
  .comment-body {
    margin-bottom: 20px;
  }
}
.comment-form {
  margin-top: 40px;
  .form--btn {
    outline: none;
    border: none;
  }

  .form-inner {
    margin-bottom: 30px;
    input,
    textarea {
      width: 100%;
      background: #f1f1f1;
      border: none;
      outline: none;
      padding: 18px 15px;
      border-radius: 5px;
      transition: all 0.42s ease;
      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        font-family: $main-font;
      }
      &:focus {
        background: rgba($primary-color1, 0.2);
      }
    }
  }
}
.blog-widget-item {
  background: #ffffff;
  box-shadow: 5px 2px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 30px 25px;
  margin-bottom: 40px;
}
// sidebar
.blog-sidebar {
  .search-area {
    .form-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;

      input {
        border: 1px solid $border-color;
        outline: none;
        padding: 12px 15px;
        color: $text-color;
        font-size: 1rem;
        font-weight: 400;
        font-family: $main-font;
        width: 100%;
        &:focus {
          border-left: 1px solid $primary-color1;
        }

        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: $text-color2;
        }
      }
      .search--btn {
        outline: none;
        border: 1px solid $primary-color1;
        background: $primary-color1;
        padding: 11px;
        .bx {
          color: $white;
          font-size: 24px;
        }
      }
    }
  }
}

/*================================================
  Error page
=================================================*/
.error-section {
  position: relative;
  z-index: 1;
  overflow: hidden;

  .section-bg-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .section-bg-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .evector1 {
    position: absolute;
    top: 30%;
    right: 30%;
    z-index: -1;
  }
  .evector2 {
    position: absolute;
    top: 60%;
    right: 30%;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .evector3 {
    position: absolute;
    top: 30%;
    left: 30%;
    animation: bounce 2s linear infinite alternate;
    z-index: -1;
    @include md-down-device() {
      left: 2%;
    }
  }
  @keyframes bounce {
    0% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  .evector4 {
    position: absolute;
    top: 60%;
    left: 23%;
    z-index: -1;
    @include md-down-device() {
      display: none;
      visibility: hidden;
    }
  }
  .error-wrapper {
    padding: 0px 30px;
    overflow: hidden;
    .error-bg {
      animation: rotating 2.5s linear infinite;
      margin-bottom: 35px;
    }
    @keyframes rotating {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(3deg);
      }
      50% {
        transform: rotate(0deg);
      }
      75% {
        transform: rotate(-3deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
    .error-content {
      h2 {
        font-size: 40px;
        font-weight: 600;
        color: $text-color;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 45px;
      }
    }
  }
}

.select-contact__control {
  border-color: #eee !important;
}

.select-contact__indicator-separator{
  background-color: #eee !important;
}
